<template>
    <div class="advertise__preview" v-if="car">
        <div class="advertise__preview__pay-and-publish">
            <p>This is a preview of your car advert. Your ad is not yet live. To make your ad live press</p>
            <button class="advertise__preview__button" v-on:click="nextStep()">{{getButtonText}}</button>
        </div>
        <div class="advertise__preview__divider">Your Ad</div>


        <div class="advertise__preview_myad">
            <div class="ad">
                <ad-galleries :car="this.car" ref="child" advertising="true"></ad-galleries>
                <div class="wrapper">

                <div class="ad__title">
                    <div class="ad__title_left" v-if="this.car">
                        <h1>{{ this.car.reg_year }}{{ formattedRegPrefix}} {{ this.car.make.make }} {{ this.car.model.model }}</h1>
                        <h2>{{ this.car.variant }} {{ this.car.transmission_type.transmission_type }}</h2>
                    </div>
                    <div class="ad__title_right ad__title_right--mobile">

                            <span class="payments" v-if="!checkDisabled( formattedMonthlyPrice )" v-on:click="openHirePurchaseModal">
                                <a class="link-payments" href="javascript:void(0)">
                                    <dl>
                                        <dt>from</dt>
                                        <dd>{{ formattedMonthlyPrice }} pm</dd>
                                    </dl>
                                </a>
                            </span>

                        <span class="price">{{ formattedCarPrice }}</span>
                    </div>
                    <div class="ad__title_right ad__title_right--desktop">
                            <span class="payments" v-if="!checkDisabled( formattedMonthlyPrice )" v-on:click="openHirePurchaseModal">
                                <a class="link-payments" href="javascript:void(0)">
                                    <dl>
                                        <dt>Monthly</dt>
                                        <dd>{{ formattedMonthlyPrice }}</dd>
                                    </dl>
                                </a>
                            </span>

                        <span class="price">{{ formattedCarPrice }}</span>
                    </div>
                </div>

                <div class="ad__cols">

                    <div class="ad__left">

                        <div class="ad__body">

                            <div class="ad__dealerinfo">
                                <h3>{{ dealerDisplayName }}</h3>
                                <div class="ad__dealerinfo_left">
                                    <div class="stars stars--med" v-if="false">
                                        <span v-for="(star,idx) in numberStars" v-bind:key="idx"></span>
                                        <span class="stars__empty" v-for="(star,idx) in (5-numberStars)"
                                              v-bind:key="idx+100"></span>
                                    </div>
                                    <small v-if="true==false">{{ formattedAverageRating }}/5</small>
                                </div>
                                <div class="ad__dealerinfo_mid">
                                </div>
                            </div>

                            <div class="ad__cardetails">
                                <h3>Car Details</h3>

                                <ul>
                                    <li v-if="this.car"><span>Make:</span> <span>{{ this.car.make.make }}</span></li>
                                    <li v-if="this.car"><span>Model:</span> <span>{{ this.car.model.model }}</span></li>
                                    <li v-if="this.car"><span>Year:</span> <span>{{ this.car.reg_year }}</span></li>
                                    <li v-if="formattedCarPrice"><span>Price:</span> <span>{{ formattedCarPrice }}</span></li>
                                    <li v-if="formattedMonthlyPrice"> <span>Monthly Price From:</span> <span
                                        v-bind:class="{ disabled: checkDisabled( formattedMonthlyPrice ) }"><a href="javascript:void(0)" v-on:click="openHirePurchaseModal">{{ formattedMonthlyPrice }}</a></span>
                                    </li>
                                    <li v-if="formattedEngineSize"><span>Engine Size:</span> <span
                                        v-bind:class="{ disabled: checkDisabled( formattedEngineSize ) }">{{ formattedEngineSize }}</span>
                                    </li>
                                    <li v-if="formattedMileage"><span>Mileage:</span> <span
                                        v-bind:class="{ disabled: checkDisabled( formattedMileage ), disabled: checkDisabled( formattedMileageKm ) }">{{ formattedMileage }} / {{ formattedMileageKm }}</span>
                                    </li>
                                    <li v-if="formattedTransmissionType"><span>Gearbox:</span> <span
                                        v-bind:class="{ disabled: checkDisabled( formattedTransmissionType ) }">{{ formattedTransmissionType }}</span>
                                    </li>
                                    <li v-if="formattedFuelType"><span>Fuel Type:</span> <span
                                        v-bind:class="{ disabled: checkDisabled( formattedFuelType ) }">{{ formattedFuelType }}</span>
                                    </li>
                                    <li v-if="formattedOwners"><span>Owners:</span> <span
                                        v-bind:class="{ disabled: checkDisabled( formattedOwners ) }">{{ formattedOwners }}</span>
                                    </li>
                                    <li v-if="formattedBodyType"><span>Body Type:</span> <span
                                        v-bind:class="{ disabled: checkDisabled( formattedBodyType ) }">{{ formattedBodyType }}</span>
                                    </li>
                                    <li v-if="formattedSeats"><span>Seats:</span> <span
                                        v-bind:class="{ disabled: checkDisabled( formattedSeats ) }">{{ formattedSeats }}</span>
                                    </li>
                                    <li v-if="formattedDoors"><span>Doors:</span> <span
                                        v-bind:class="{ disabled: checkDisabled( formattedDoors ) }">{{ formattedDoors }}</span>
                                    </li>
                                    <li v-if="formattedColour"><span>Colour:</span> <span
                                        v-bind:class="{ disabled: checkDisabled( formattedColour ) }">{{ formattedColour }}</span>
                                    </li>
                                    <li v-if="formattedEnginePower"><span>Engine Power:</span> <span
                                        v-bind:class="{ disabled: checkDisabled( formattedEnginePower ) }">{{ formattedEnginePower }}</span>
                                    </li>
                                    <li v-if="formattedWarranty != null">
                                        <span>Warranty:</span> <span>{{ formattedWarranty }}</span>
                                    </li>
                                    <li v-if="formattedTorque"><span>Engine Torque:</span> <span
                                        v-bind:class="{ disabled: checkDisabled( formattedTorque ) }">{{ formattedTorque }}</span>
                                    </li>
                                    <li v-if="formattedFuelEconUrban"><span>Fuel Econ Urban:</span> <span
                                        v-bind:class="{ disabled: checkDisabled( formattedFuelEconUrban ) }">{{ formattedFuelEconUrban }}</span>
                                    </li>
                                    <li v-if="formattedFuelEconRural"><span>Fuel Econ Rural:</span> <span
                                        v-bind:class="{ disabled: checkDisabled( formattedFuelEconRural ) }">{{ formattedFuelEconRural }}</span>
                                    </li>
                                    <li v-if="formattedFuelEconCombined"><span>Fuel Econ Combined:</span> <span
                                        v-bind:class="{ disabled: checkDisabled( formattedFuelEconCombined ) }">{{ formattedFuelEconCombined }}</span>
                                    </li>
                                    <li v-if="formattedRoadTax"><span>Road Tax:</span> <span
                                        v-bind:class="{ disabled: checkDisabled( formattedRoadTax ) }">{{ formattedRoadTax }}</span>
                                    </li>
                                    <li v-if="formattedRoadTaxDue"><span>Road Tax Due:</span> <span
                                        v-bind:class="{ disabled: checkDisabled( formattedRoadTaxDue ) }">{{ formattedRoadTaxDue }}</span>
                                    </li>
                                    <li v-if="formattedNCTDue"><span>NCT Due:</span> <span
                                        v-bind:class="{ disabled: checkDisabled( formattedNCTDue ) }">{{ formattedNCTDue }}</span>
                                    </li>
                                    <li v-if="formattedAcceleration"><span>0 - 62 mph:</span> <span
                                        v-bind:class="{ disabled: checkDisabled( formattedAcceleration ) }">{{ formattedAcceleration }}</span>
                                    </li>
                                    <li v-if="formattedTopSpeed"><span>Top Speed:</span> <span
                                        v-bind:class="{ disabled: checkDisabled( formattedTopSpeed ) }">{{ formattedTopSpeed }}</span>
                                    </li>
                                    <li v-if="ncapChild || ncapAdult || environmental || ncapOverall"><span class="ad__cardetails_title">Ratings</span></li>
                                    <li v-if="ncapChild">
                                        <span>Child Safety:</span>
                                        <span v-bind:class="{ disabled: checkDisabled( ncapChild ) }">
                                                <span v-if="checkDisabled( ncapChild )">{{ncapChild}}</span>
                                                <div class="stars stars--med" v-if="!checkDisabled( ncapChild )">
                                                    <span v-for="(star,idx) in ncapChild" v-bind:key="idx+110"></span>
                                                    <span class="stars__empty" v-for="(star, idx) in 5-ncapChild" v-bind:key="idx+120"></span>
                                                </div>
                                            </span>
                                    </li>
                                    <li v-if="ncapAdult">
                                        <span>Adult Safety:</span>
                                        <span v-bind:class="{ disabled: checkDisabled( ncapAdult ) }">
                                                <span v-if="checkDisabled( ncapAdult )">{{ncapAdult}}</span>
                                                <div class="stars stars--med" v-if="!checkDisabled( ncapAdult )">
                                                    <span v-for="(star, idx) in ncapAdult" v-bind:key="idx+130"></span>
                                                    <span class="stars__empty" v-for="(star, idx) in 5-ncapAdult" v-bind:key="idx+140"></span>
                                                </div>
                                            </span>
                                    </li>
                                    <li v-if="ncapOverall">
                                        <span>Overall Safety:</span>
                                        <span v-bind:class="{ disabled: checkDisabled( ncapOverall ) }">
                                                <span v-if="checkDisabled( ncapOverall )">{{ncapOverall}}</span>
                                                <div class="stars stars--med" v-if="!checkDisabled( ncapOverall )">
                                                    <span v-for="(star,idx) in ncapOverall" v-bind:key="idx+150"></span>
                                                    <span class="stars__empty" v-for="(star, idx) in 5-ncapOverall" v-bind:key="idx+160"></span>
                                                </div>
                                            </span>
                                    </li>
                                    <li v-if="environmental">
                                        <span>Environmental:</span>
                                        <span v-bind:class="{ disabled: checkDisabled( environmental ) }">
                                                <span v-if="checkDisabled( environmental )">{{ environmental }}</span>
                                                <div class="stars stars--med stars--green" v-if="!checkDisabled( environmental )">
                                                    <span v-for="(star, idx) in environmental" v-bind:key="idx+170"></span>
                                                    <span class="stars__empty" v-for="(star, idx) in 5-environmental" v-bind:key="idx+180"></span>
                                                </div>
                                            </span>
                                    </li>
                                    <li v-if="formattedUpdatedAtDate"><span>Last Updated:</span> <span v-bind:class="{ disabled: checkDisabled( formattedUpdatedAtDate ) }">{{ formattedUpdatedAtDate }}</span></li>
                                </ul>
                            </div>

                            <div class="ad__info">
                                <h3>Vehicle Features:</h3>
                                <p v-if="car" class="dont-break-out"><span v-if="car.description">{{ car.description.replace(/\.$/, '').trim()}}.</span>  <span v-if="this.getDescriptionData().length > 0">{{this.getDescriptionData()}}.</span></p>
<!--                                <h3>More Information</h3>-->

<!--                                <ad-collapsable-info :car="car"></ad-collapsable-info>-->

                            </div>
                        </div>

<!--                        <ad-videos :car="car" v-if="this.car"></ad-videos>-->

    <!--                    <dealer-bottom-contact-form :car="car" v-if="this.car"/>-->

    <!--                    <ad-action-links v-if="this.car" :ad-id="this.car.id" :make="this.car.make" :model="this.car.model" :slug="this.car.slug"></ad-action-links>-->
                        <div class="mobileonly">
                            <div class="seller-details seller-details">
                                <h3>Seller Details</h3>
                                <div class="inner">
                                    <div class="label">Name:</div><div class="val" v-if="car.adv_settings">{{ car.adv_settings.contact_name }}</div>
                                </div>
                                <div class="inner">
                                    <div class="label">Phone:</div><div class="val" v-if="car.adv_settings">{{ car.adv_settings.phone }}</div>
                                </div>
                                <div class="inner">
                                    <div class="label">Location:</div><div class="val" v-if="car.adv_settings">{{ car.location.region }}</div>
                                </div>
                            </div>

                            <p class="observations" v-if="car.adv_settings && car.adv_settings.email_optin">
                                Buyer can contact you using an enquiry form that will send an email to {{ car.adv_settings.email }}
                            </p>

                        </div>
                    </div>

                    <div class="ad__right desktoponly">
                        <div class="seller-details">
                            <h3>Seller Details</h3>
                            <div class="inner">
                                <div class="label">Name:</div><div class="val" v-if="car.adv_settings">{{ car.adv_settings.contact_name }}</div>
                            </div>
                            <div class="inner">
                                <div class="label">Phone:</div><div class="val" v-if="car.adv_settings">{{ car.adv_settings.phone }}</div>
                            </div>
                            <div class="inner">
                                <div class="label">Location:</div><div class="val" v-if="car.adv_settings">{{ car.location.region }}</div>
                            </div>
                        </div>
                        <p class="observations" v-if="car.adv_settings && car.adv_settings.email_optin">
                            Buyer can contact you using an enquiry form that will send an email to {{ car.adv_settings.email }}
                        </p>
                    </div>

                </div>
            </div>
            </div>


        </div>
        <div class="advertise__buttons advertise__buttons">
            <button class="advertise__button cancel disabled" v-on:click="previousStep()">Back</button>
            <button class="advertise__button pay" v-on:click="nextStep()" data-cy="btn-pay">{{getButtonText}}</button>
        </div>

        <image-gallery v-if="imageGalleryOpen" :car="car"></image-gallery>

        <video-modal v-if="modalVideoOpen" :car="car"></video-modal>

    </div>
</template>

<script>
    import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
    import '../../../node_modules/swiper/css/swiper.css'
    import $ from "jquery";
    import moment from "moment";
    import numeral from "numeral";
    import AdCollapsableInfo from '@/components/partials/AdCollapsableInfo'
    import AdVideos from '@/components/partials/AdVideos'
    import ImageGallery from '@/components/partials/ImageGallery'
    import AdGalleries from '@/components/partials/AdGalleries'
    import VideoModal from "@/components/partials/VideoModal";
    import EventBus from "../../event-bus"

    export default {
        name: "Preview",
        components: {
            Swiper,
            SwiperSlide,
            AdCollapsableInfo,
            AdVideos,
            ImageGallery,
            AdGalleries,
            VideoModal
        },
        data: function () {
            return {
                car: false,
                showForm: false,
                attempts:0,
                maxRetries: 10,
                imageGalleryOpen: false,
                modalVideoOpen: false,
                showResult: false,
                hasVideo:false,
                swiperOptions: {
                    loop: true,
                    slidesPerView: 'auto',
                    centeredSlides: true,
                    spaceBetween: 30,
                    preloadImages: false,
                    lazy: {
                        loadPrevNext: true
                    },
                    autoHeight: false,
                    pagination: {
                        el: '.ad__mobilegallery_imagecount',
                        type: 'custom',
                        renderCustom: function (swiper, current){
                            return current + ' of 40';
                        },
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    },
                },
                likes: [],
                liked: false,
                newImages: [],
                swiperSlides: [],
            }
        },
        mounted() {
            let that = this;
            EventBus.$on('openImageGallery', function () {
                that.imageGalleryOpen = true;
            });

            EventBus.$on('closeImageGallery', function () {
                that.imageGalleryOpen = false;
            });

            EventBus.$on('closeVideoModal', function () {
                that.modalVideoOpen = false;
            });

            EventBus.$on('openVideoModal', function () {
                that.modalVideoOpen = true;
            });

            setTimeout( () => {
                this.getAdData();
            },3000);

            setTimeout( () => {
                this.getAdData();
            },4000);

            setTimeout( () => {
                this.getAdData();
            },5000);

            let refreshId = setInterval(() => {
                if(this.attempts < this.maxRetries){
                    this.getAdData();
                    this.attempts++;
                }else{
                    clearInterval(refreshId);
                }
            }, 10000);


            this.getAdData();
        },
        computed:{
            getButtonText(){
                return this.editMode ? "Publish Ad" : "Pay & Publish Ad";
            },
            editMode(){
                if(typeof window === 'undefined') return false;
                return typeof sessionStorage.getItem('editMode') !== 'undefined' &&
                    sessionStorage.getItem('editMode') == 'true';
            },
            videoUrl() {
                return  'https://youtube.com/embed/' + this.car.youtube_id;
            },
            mainImage() {
                return this.newImages != null && this.newImages.length > 0 && this.newImages[0].primaryURL.xl != null ? this.newImages[0].primaryURL.xl : require('../../assets/img/photos/missing/large.jpg');
            },
            dealerDisplayName(){
                return this.car != null && this.car.dealer ? this.car.dealer.display_name : null;
            },
            formattedTransmissionType() {
                return this.car != null && this.car.transmission_type.transmission_type != null ? this.car.transmission_type.transmission_type : null;
            },
            formattedFuelType() {
                return this.car != null && this.car.fuel_type.fuel_type != null ? this.car.fuel_type.fuel_type : null;
            },
            formattedOwners() {
                return this.car != null && this.car.owners != null ? this.car.owners : null;
            },
            formattedBodyType() {
                return this.car != null && this.car.body_type.body_type != null ? this.car.body_type.body_type : null;
            },
            formattedSeats() {
                return this.car != null && this.car.seats != null ? this.car.seats : null;
            },
            formattedDoors() {
                return this.car != null && this.car.doors != null ? this.car.doors : null;
            },
            formattedColour() {
                return this.car != null && this.car.colour != null ? this.car.colour.colour : null;
            },
            formattedRegion() {
                return this.car != null && this.car.dealer != null && this.car.dealer.region != null ? this.car.dealer.region : '';
            },

            formattedUpdatedAtDate() {
                return this.car && this.car.dealer != null && this.car.dealer.stock_updated_at != null ? moment(this.car.dealer.stock_updated_at).format('DD/MM/YYYY') : null;
            },

            formattedEngineSize() {
                return this.car && this.car.engine_cc != null ? numeral(this.car.engine_cc / 1000).format('0.0') : null;
            },

            formattedEnginePower() {
                let torque = this.car ?  this.car.max_power_bhp : null;
                return torque != null ?
                    numeral(torque).format('0') + ' BHP' : null;
            },

            formattedWarranty(){
                let months = this.car != null && this.car.warranty_period_months != null ? this.car.warranty_period_months : null;
                let res = null;

                if(months != null){
                    if(months < 12){
                        res = months + ' Months';
                    }else{
                        let years = parseInt(months / 12);
                        let suffix =  years > 1 ? ' Years' : ' Year';
                        res = years.toString() + suffix;
                    }
                }
                return res;
            },
            formattedTorque() {
                let res = null;
                if(this.car && this.car.ad_extra_info != null && this.car.ad_extra_info.max_torque_nm != null){
                    if(this.car.fuel_type.toLowerCase() == 'electric'){
                        res = numeral(this.car.ad_extra_info.max_torque_nm).format('0') + ' Nm';
                    }else{
                        res = numeral(this.car.ad_extra_info.max_torque_nm).format('0') + ' Nm @ ' + numeral(this.car.ad_extra_info.max_torque_revs).format('0') + ' rpm';
                    }
                }
                return res;
            },
            formattedFuelEconUrban() {
                let mpg = this.car && this.car.ad_extra_info != null && this.car.ad_extra_info.fuel_cons_urban != null ? numeral(this.car.ad_extra_info.fuel_cons_urban).format('0.0') + ' mpg' : '';
                if (mpg === '') {
                    return null;
                }
                return mpg;
            },

            formattedFuelEconRural() {
                let mpg = this.car && this.car.ad_extra_info != null && this.car.ad_extra_info.fuel_cons_extra_urban != null ? numeral(this.car.ad_extra_info.fuel_cons_extra_urban).format('0.0') + ' mpg' : '';
                if (mpg === '') {
                    return null;
                }
                return mpg;
            },

            formattedFuelEconCombined() {
                let mpg = this.car && this.car.ad_extra_info != null && this.car.ad_extra_info.fuel_cons_combined != null ? numeral(this.car.ad_extra_info.fuel_cons_combined).format('0.0') + ' mpg' : '';
                if (mpg === '') {
                    return null;
                }
                return mpg;
            },

            formattedRoadTax() {
                return this.car && this.car.road_tax_cost != null ? '€' + numeral(this.car.road_tax_cost).format('0') : null;
            },

            formattedRoadTaxDue() {
                return this.car && this.car.tax_due_date != null ? moment(this.car.tax_due_date).format('DD/MM/YYYY') : null;
            },

            formattedAcceleration() {
                return this.car && this.car.acceleration != null ? numeral(this.car.acceleration).format('0.0') + ' seconds' : null;
            },

            formattedTopSpeed() {
                return this.car && this.car.ad_extra_info != null && this.car.ad_extra_info.max_speed_mph != null ? numeral(this.car.ad_extra_info.max_speed_mph).format('0') + ' mph' : null;
            },

            formattedMileage() {
                return this.car && this.car.mileage != null ? numeral(this.car.mileage).format('0,0') + " mi" : null;
            },

            formattedMileageKm() {
                return this.car && this.car.kilometres != null ? numeral(this.car.kilometres).format('0,0') + " km" : null;
            },

            formattedNCTDue() {
                return this.car && this.car.test_due_date != null ? moment(this.car.test_due_date).format('DD/MM/YYYY') : null;
            },

            formattedRegPrefix() {
                return this.car && this.car.reg_prefix != null ? ' (' + this.car.reg_prefix + ')' : '';
            },

            formattedCarPrice() {
                return this.car && this.car.price != null && this.car.price > 10 ? '€' + numeral(this.car.price).format('0,0') : 'POA';
            },

            formattedMonthlyPrice() {
                return this.car && this.car.from_monthly != null ? '€' + numeral(this.car.from_monthly).format('0,0') : null;
            },

            ncapChild() {
                return this.car && this.car.ad_extra_info != null && this.car.ad_extra_info.ncap_child_pct != null ? Math.ceil(((parseInt(this.car.ad_extra_info.ncap_child_pct) * 5) / 100)) : null;
            },

            ncapAdult() {
                return this.car && this.car.ad_extra_info != null && this.car.ad_extra_info.ncap_adult_pct != null ? Math.ceil(((parseInt(this.car.ad_extra_info.ncap_adult_pct) * 5) / 100)) : null;
            },

            ncapOverall() {
                return this.car && this.car.ad_extra_info != null && this.car.ad_extra_info.ncap_overall != null ? Math.ceil(this.car.ad_extra_info.ncap_overall) : null;
            },

            environmental() {
                return this.car && this.car.ad_extra_info != null && this.car.ad_extra_info.co2_stars != null ? Math.ceil(this.car.ad_extra_info.co2_stars) : null;
            },

            numberStars() {
                return this.car != null && this.car.dealer != null && this.car.dealer != null && this.car.dealer.average_rating != null ? Math.round(this.car.dealer.average_rating) : 0;
            },
            formattedAverageRating() {
                return this.car != null && this.car.dealer != null && this.car.dealer != null && this.car.dealer.average_rating != null ? numeral(this.car.dealer.average_rating).format('0.00') : 0;
            },
            getGalleryImages() {
                if(this.car == null){
                    return false;
                }
                let arr = [];
                let maxSlice = this.car.images.length >= 2 ? 2 : this.car.images.length;
                arr.push(...this.car.images.slice(0, maxSlice));

                if (this.isVideoAvailable) {
                    let video = {
                        'primaryURL': {
                            'xl': this.getVideoImage(),
                            'xs': this.getVideoImage()
                        },
                        'hasVideo' : true
                    };
                    arr.push(video);
                }

                if (this.car.images.length > 2) {
                    arr.push(...this.car.images.slice(2));
                }

                return arr;
            },
        },
        methods:{
            getAdData(){
                return this.axios.get(process.env.VUE_APP_API_URL + '/api/advertise/preview/' + sessionStorage.getItem('adId'), {})
                    .then(response => {
                        this.car = response.data.ad;
                        // if(typeof this.$refs.child !== 'undefined'){
                        //     // this.$refs.child.$props.car = response.data.ad;
                        // }
                        this.car.images = response.data.images;
                        this.swiperSlides = response.data.images;
                        // this.car.reg = response.data.ad.reg;
                        // this.car.reg_year = response.data.ad.reg_year;
                        // this.car.make = response.data.ad.make.make;
                        // this.car.model = response.data.ad.model.model;
                        this.newImages = response.data.images;
                        //Go to Next Step
                    })
                    .catch(error => console.error(error));
            },
            openHirePurchaseModal() {
                EventBus.$emit('openHirePurchaseModal');
            },
            checkDisabled(val) {
                return val == null;
            },
            getDescriptionData(){
                let res = [];
                if(typeof this.car.feature !== 'undefined'){
                    res = this.car.feature.map((val) => val.description);
                }
                return res.join(', ');
            },
            playVideo(){

                let element = document.getElementsByClassName("swiper-slide-active");
                element[0].childNodes[0].style.display='none';
                let node = document.createElement("iframe");
                node.src = this.formattedYoutubeUrl;
                node.className = "youtube-video video-iframe";

                let containerNode = document.createElement("div");
                containerNode.className = "resp-container";
                containerNode.appendChild(node);
                element[0].appendChild(containerNode);

                let player = document.getElementById("playerSimple");
                player.classList.remove("hasVideo");

                let spinner = document.getElementById('swiper-lazy-preloader');
                spinner.style.display = 'none';

                document.getElementsByClassName('ad__mobilegallery_imagecount')[0].style.display = 'none';
                document.getElementsByClassName('ad__mobilegallery_video')[1].style.display='none'

                //Autoplay is no longer working. commented for now.
                // setTimeout(function(){
                //$('.swiper-slide-active').find('.youtube-video')[0].contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
                // }, 500);

            },
            openVideoModal() {
                if (this.isVideoAvailable) {
                    EventBus.$emit('openVideoModal');
                } else {
                    if(this.car.images.length > 0) {
                        EventBus.$emit('openImageGallery');
                    }
                }
            },
            isVideoAvailable() {
                return this.swiperSlides != null ? true : false;
            },
            openImageGallery() {
                this.imageGalleryOpen = true;
            },
            checkIframe(){
                $('.swiper-slide-active').find('.resp-container').remove();
                let active = document.getElementsByClassName("swiper-slide-active");
                active[0].children[0].style.display='block';
                document.getElementsByClassName('ad__mobilegallery_imagecount')[0].style.display = 'block';
                if(document.getElementsByClassName('ad__mobilegallery_video')[1]){
                    document.getElementsByClassName('ad__mobilegallery_video')[1].style.display='block';
                }
            },
            getVideoImage(){
                let url;
                if (this.isVideoAvailable) {
                    url = process.env.VUE_APP_API_URL + "/api/image-fetch/" + this.car.youtube_id;
                }
                return url;
            },
            lookup(event){
                this.showResult = true;
                this.showForm = false;
                event.preventDefault();
            },
            enableForm(){
                this.showResult = false;
                this.showForm = true;
            },
            isPaid(){
                if(typeof window === 'undefined') return false;

                return (this.car.adv_settings.payment_id != null && this.car.adv_settings.payment_id.length > 0) || (typeof sessionStorage.getItem('editMode') !== 'undefined' && sessionStorage.getItem('editMode') == 'true');
            },
            nextStep() {

                if(this.isPaid()){
                    window.NativeEventBus.dispatchEvent("goToEditSuccess");
                }else{
                    window.NativeEventBus.dispatchEvent("nextStep");
                }

            },
            previousStep() {
                window.NativeEventBus.dispatchEvent("previousStep");
            }
        }
    }
</script>

<style scoped>
</style>
