<template>
    <div>
        <main-header></main-header>

        <div class="wrapper--full">

            <div class="pagenotfound">
                <div class="pagenotfound__content">
                    <img src="../assets/img/404.svg" alt="404"/>
                    <h1>Sorry, the page you're looking for cannot be found</h1>
                </div>
            </div>

        </div>

        <main-footer></main-footer>
    </div>
</template>

<script>
    import MainHeader from '@/components/MainHeader.vue'
    import MainFooter from '@/components/MainFooter'

    export default {
        name: "PageNotFound",
        components: {
            MainHeader,
            MainFooter,
        },
        serverPrefetch() {
            this.$ssrContext.title = 'Page Not Found | Terrific.ie';
            this.$ssrContext.description = 'Page Not Found';
            this.$ssrContext.keywords = 'Page Not Found';
            this.$ssrContext.robots = 'all';
        }
    }
</script>

<style scoped>

</style>
