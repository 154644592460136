<template>
    <div>
        <main-header></main-header>
        <div class="wrapper wrapper--full wrapper--grey">
            <div class="wrapper wrapper--grey">

                <div class="advertise">

                    <div class="advertise__landing">
                        <h1>Email Alert</h1>

                        <div class="advertise__landing_wrapper">
                            <div class="advertise__landing_panel">
                                <h2>Deactivate Email Alert</h2>
                                <p v-if="this.$route.params.status == 'success'">You are unsubscribed and you will no longer recieve this alert email. Thank you.</p>
                                <p v-if="this.$route.params.status == 'error'">We had a problem deactivating your email alert. Please try again later.</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <main-footer></main-footer>
    </div>
</template>

<script>
    import MainHeader from '@/components/MainHeader.vue'
    import MainFooter from '@/components/MainFooter'
    export default {
        name: "DeactivateEmailAlert",
        components: {
            MainHeader,
            MainFooter
        },
        data: function () {
            return {
                title: "Deactivate Email Alert | Terrific.ie",
                description: "Deactivate Email Alert.",
                keywords: "Cars for Sale, Used Cars, Cars, Second Hand Cars, Electric Cars, Audi, BMW, Ford, Toyota, 7 Seaters, Land Rover",
                robots: "all"
            }
        },
        serverPrefetch() {
            this.$ssrContext.title = this.title;
            this.$ssrContext.description = this.description;
            this.$ssrContext.keywords = this.keywords;
            this.$ssrContext.robots = this.robots;
        }
    }
</script>

<style scoped>

</style>
