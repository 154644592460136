<template>
    <div class="quicksearch-widget">
        <quick-search-first-auto :monthlyPrice="true" :widget="true"></quick-search-first-auto>
    </div>
</template>

<script>
    import QuickSearchFirstAuto from '@/components/QuickSearchFirstAuto'

    export default {
        name: "QuickSearchWidget",
        components: {
            QuickSearchFirstAuto,
        },
        data: function () {
            return {
                title: "Quick Search | Terrific.ie",
                description: "Terrific.ie used cars for sale in Ireland including electric cars, 7 seaters and SUV’s.",
                keywords: "Cars for Sale, Used Cars, Cars, Second Hand Cars, Electric Cars, Audi, BMW, Ford, Toyota, 7 Seaters, Land Rover",
                robots: "all"
            }
        },
        serverPrefetch () {
            this.$ssrContext.title = this.title;
            this.$ssrContext.description = this.description;
            this.$ssrContext.keywords = this.keywords;
            this.$ssrContext.robots = this.robots;
        },
    }
</script>

<style>
    .quicksearch-widget {
        width: 100%;
        height: 100vh;
        margin-top: -70px;
        background-color: #313C53;
    }
</style>
