<template>
    <div>
        <main-header></main-header>
        <breadcrumbs></breadcrumbs>
        <seo-meta></seo-meta>

        <div class="wrapper wrapper--grey wrapper--full">
            <div class="wrapper">

                <div class="pagetitle">
                    <h1>Industry News</h1>
                </div>

            </div>
        </div>

        <div class="wrapper wrapper--grey wrapper--full">
            <div class="news-article">
                <div v-if="article">
                    <div  v-for="(htmlBlock, index) in this.myHtmlContent" :key="htmlBlock" >
                        <div v-html="htmlBlock.replace('<img', '<img width=\'808\' height=\'503\'')" v-bind:class="{ image: checkImgTagExist(htmlBlock) , block:true }"></div>
                        <div v-if="index==0">
                            <div class="entry-meta">
                                <span class="date">
                                    <span class="news__calendar">&nbsp;</span>
                                    <time class="entry-date">{{ formattedDate(article) }}</time>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <main-footer></main-footer>
    </div>
</template>

<script>
    import MainHeader from '@/components/MainHeader.vue'
    import MainFooter from '@/components/MainFooter'
    import Breadcrumbs from '@/components/partials/Breadcrumbs'
    import SeoMeta from "@/components/partials/SeoMeta";
    import moment from 'moment';

    export default ({
        name: "IndustryNewsArticle",
        components: {
            MainHeader,
            MainFooter,
            Breadcrumbs,
            SeoMeta
        },
        data: function () {
            return {
                title: "Motor Industry News | Terrific.ie",
                description: "News from the Irish Motoring Industry",
                htmlContent: '',
                keywords: "Irish Motoring Industry",
                robots: "all",
                routeName: this.$router.currentRoute.name,
                edjsHTML: require("editorjs-html"),
                edjsParser: null
            }
        },
        watch: {
            $route() {
                this.updateBreadCrumb();
            }
        },
        serverPrefetch() {
            this.$ssrContext.title = this.title;
            this.$ssrContext.description = this.description;
            this.$ssrContext.keywords = this.keywords;
            this.$ssrContext.robots = this.robots;
            return this.getArticle();
        },
        mounted() {
            if (!this.article) {
                this.getArticle();
            }
            this.updateMyBreadCrumb();
        },
        computed: {
            article() {
                return this.$store.state.industrynews.industryNewsArticle[this.$route.params.slug];
            },
            myHtmlContent(){
                if(typeof  this.article.data_json === 'undefined' || !this.article.data_json){
                    return false;
                }

                // Parse this block in editorjs-html
                function customParser(block){
                    return `${block.data.html}`;
                }
                return this.edjsHTML({raw: customParser}).parse(JSON.parse(this.article.data_json));
            }
        },
        methods: {
            checkImgTagExist(node){
                return /<img[^>]+src="https:\/\/([^">]+)/g.test(node);
            },
            updateMyBreadCrumb(){

                //If not found, redirect to 404 page
                if(typeof window !== 'undefined' && !this.article){
                    return this.$router.push('/not-found');
                }else{
                    this.$store.commit('breadcrumbs/setLevelOne',  true);

                    this.$store.commit('breadcrumbs/setFirstLevelName',  "Industry News");
                    this.$store.commit('breadcrumbs/setPathLevelOne',  '/news');

                    this.$store.commit('breadcrumbs/setLevelTwo',  false);
                    this.$store.commit('breadcrumbs/setSecondLevelName',  "");
                    this.$store.commit('breadcrumbs/setPathLevelTwo',  "");

                    this.$store.commit('breadcrumbs/setLevelThree',  false);
                    this.$store.commit('breadcrumbs/setThirdLevelName',  '');

                    this.$store.commit('breadcrumbs/setLevelFour',  false);
                    this.$store.commit('breadcrumbs/setFourthLevelName',  '');

                    this.$store.commit('breadcrumbs/setLeafLevelName',  this.article.title);
                }
            },
            async getArticle() {
                await this.$store.dispatch('industrynews/fetchIndustryNewsArticle', this.$route.params.slug);
                this.updateMyBreadCrumb();
            },
            formattedDate(node) {
                return moment(node.created_at).format('MMMM DD, YYYY');
            },

        }
    })
</script>

<style scoped>
</style>
