<template>
    <div>
        <div class="advertise__panel">
            <div class="advertise__images">
                <div  class="form-error-top" v-if="Object.keys(errorResponse500).length > 0">General problem. Please try again later.  </div>
                <h2>Vehicle Images</h2>
                <p>Upload up to 15 photos of your vehicle <span>(.jpg or .png supported)</span> Drag to re-order.</p>
                <div v-if="this.instance && this.instance.files.length > 0" class="advertise__images_photo advertise__images_photo--main">
                    <div class="main-label">Main</div>
                </div>
                <dropzone id="foo" ref="el" :options="dropzoneOptions" :destroyDropzone="true" class="advertise__images_canvas foo list-group" data-id="photo-canvas" :useCustomSlot="true">
                    <div class="dropzone-custom-content">
                        <div class="advertise__images_photo advertise__images_photo--add" id="dropzone-click-target">
                            <label for="photofile"></label>
                        </div>
                    </div>
                </dropzone>
            </div>
            <div class="advertise__images_videoupload">
                <form>
                    <label>Add a YouTube video of your car below</label>
                    <input type="text" placeholder="https://youtube.com/yourvideo" class="form-control" v-model="selectedYoutubeVideo" />
                    <p v-if="errorResponse && errorResponse.errors && errorResponse.errors.youtube_id" class="form-error-youtube">Invalid youtube link</p>
                </form>
            </div>
        </div>

        <div class="advertise__buttons advertise__buttons--mobileonly">
            <button class="advertise__button" v-on:click="nextStep()" data-cy="mobile-next">Next</button>
        </div>

        <div class="advertise__buttons advertise__buttons--images advertise__buttons--desktoponly">
            <button class="advertise__button cancel" v-on:click="previousStep()">Back</button>
            <button class="advertise__button" data-class="add-photos-open" v-on:click="nextStep()" data-cy="next">Next</button>
        </div>

        <div class="modal advertise__modal advertise__modal" style="display:flex" v-if="modalValidator">
            <div class="modal-photos">
                <div class="modal__wrapper advertise__modal__wrapper--photos">
<!--                    <div class="modal__heading" @click="closeModal">-->
<!--                        <h1>xxxxxxxx</h1>-->
<!--                    </div>-->
                    <div class="modalcall__content advertise__modal__content">
                      <p>
                          You haven’t uploaded any photos yet, adverts with photos have a much greater chance of selling.
                          For this reason you must upload at least one photo before you can post your ad.
                      </p>
                        <div class="advertise__buttons advertise__modal__buttons">
                            <button class="advertise__button advertise__modal__button" v-on:click="closeModal()">Add Photos</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import NativeEventBus from "../native-event-bus";
import Dropzone from 'nuxt-dropzone'
import 'nuxt-dropzone/dropzone.css'
import $ from "jquery";
import { isMobile } from 'mobile-device-detect'
import {mapMutations} from "vuex";

export default {
        name: "Photos",
        components:{
            Dropzone,
            isMobile
        },
        data: function () {
            return {
                errorResponse: [],
                errorResponse500:[],
                options: {
                    handle: '.handle',
                    filter: '.disabled'
                },
                instance: null,
                showForm: false,
                showResult: false,
                modalValidator: false,
                selectedYoutubeVideo: null,
                dropzoneOptions: {
                    url: 'https://httpbin.org/post',
                    resizeWidth: 1040,
                    resizeHeight: 780,
                    resizeQuality: 1,
                    acceptedFiles:'image/*',
                    resizeMethod: 'crop',
                    thumbnailWidth: 257,
                    thumbnailHeight: 192,
                    autoProcessQueue: false,
                    clickable: '#dropzone-click-target',
                    previewTemplate : this.getTemplate(),
                    createImageThumbnails: true,
                    maxFilesize: 500,
                    maxFiles: 40,
                    maxThumbnailFilesize: 500,
                    headers: { "My-Awesome-Header": "header value" },
                    init: function() {
                        var myDropzone = this;
                        window.myDropzone = myDropzone;

                        $.get(process.env.VUE_APP_API_URL + '/api/advertise/preview/' + sessionStorage.getItem('adId'), function(data) {

                            window.uploadCount = data.images.length;

                            $.each(data.images, function(key,value){
                                var mockFile = {
                                    mock: true,
                                    id: value.id,
                                    name: value.primaryURL.xs,
                                    url: value.primaryURL.xs,
                                    size: 200 };

                                // Call the default addedfile event handler
                                myDropzone.emit("addedfile", mockFile);

                                window.uploadImages.push({image_id: value.id, data: null});

                                // And optionally show the thumbnail of the file:
                                myDropzone.emit("thumbnail", mockFile, value.primaryURL.xs);

                                myDropzone.files.push(mockFile);
                            });

                            //Adjust the upload button
                            $('#foo').append($('.dz-message'));
                            window.updateCounters();
                                setTimeout(() => window.sortable(), 1000);
                          });

                        this.on("maxfilesexceeded", function(file){
                            this.removeFile(file);
                            console.log("No more files please!");
                            return false;
                        });

                        this.on("removedfile", function(file) {
                           console.log("removing File", file);
                        }),

                        this.on('success', function() {
                        }),
                        this.on("thumbnail", function(file) {
                            if(typeof file.mock !== 'undefined' && file.mock == true) {
                                file.previewElement.querySelector(".new_image_key").remove();
                                if(file.previewElement.querySelector("img").src.endsWith('/advertising/') == false){
                                    file.previewElement.querySelector("img").src = file.previewElement.querySelector("img").src + '?v=' + Math.floor(Date.now() / 1000);
                                }else{
                                    file.previewElement.querySelector("img").src = require('../../assets/img/photos/missing/medium.jpg');
                                }
                            }
                        });
                        this.on('addedfile', function(file) {

                            file.previewElement.querySelector("img").src = file.previewElement.querySelector("img").src + '?v=' + Math.floor(Date.now() / 1000)

                            if(typeof file.mock !== 'undefined' && file.mock == true){
                                let elm = $(file.previewElement).find('.ad_image_id');
                                $(elm).val(file.id)
                                return false;
                            }

                            if(myDropzone.getQueuedFiles().length >= 15 || myDropzone.files.length > 40){
                                this.removeFile(file);
                                return false;
                            }

                            $('#foo').append($('.dz-message'));
                            let reader = new FileReader();

                            if(document.querySelector('.main-label')){
                                document.querySelector('.main-label').style.display = 'block';
                            }

                            // Set the image once loaded into file reader
                            reader.onload = function (e) {
                                const MAX_WIDTH = 1280;
                                const MAX_HEIGHT = 720;
                                let img = document.createElement("img");
                                img.src = e.target.result;

                                img.onload = function() {
                                    var canvas = document.createElement("canvas");
                                    var ctx = canvas.getContext("2d");
                                    ctx.drawImage(img, 0, 0, MAX_WIDTH, MAX_HEIGHT);
                                    ctx.rotate(20 * Math.PI / 180);

                                    if (img.width > MAX_WIDTH || img.height > MAX_HEIGHT) {
                                        let width = img.width;
                                        let height = img.height;

                                        if (width > height) {
                                            if (width > MAX_WIDTH) {
                                                height *= MAX_WIDTH / width;
                                                width = MAX_WIDTH;
                                            }
                                        } else {
                                            if (height > MAX_HEIGHT) {
                                                width *= MAX_HEIGHT / height;
                                                height = MAX_HEIGHT;
                                            }
                                        }
                                        canvas.width = width;
                                        canvas.height = height;
                                        let ctx = canvas.getContext("2d");
                                        ctx.drawImage(img, 0, 0, width, height);
                                        file.dataURL = canvas.toDataURL(file.type);
                                    }
                                    window.uploadImages.push({image_id: null, data: file});
                                }
                            }
                            reader.readAsDataURL(file);
                            window.updateCounters();
                            window.updateNewImageKeys();
                        });
                    }
                }
            }
        },
        mounted(){
            // Everything is mounted and you can access the dropzone instance
            this.instance = this.$refs.el.dropzone;
            window.uploadImages = [];
            window.imageSort = [];

            const script = document.createElement('script');
            script.src = 'https://cdn.jsdelivr.net/npm/sortablejs@latest/Sortable.min.js';
            document.getElementsByTagName('head')[0].appendChild(script);

            sessionStorage.setItem('images', null);

            if(sessionStorage.getItem('adId') > 0){
                this.loadAd(sessionStorage.getItem('adId'));
            }

            window.NativeEventBus.addEventListener('setIsLoading', () => {
                this.setIsLoading(true);
            });

            window.NativeEventBus.addEventListener('unsetIsLoading', () => {
                this.setIsLoading(false);
            });

            window.NativeEventBus.addEventListener('uploadPhotos', event => {
                this.uploadPhotos(event);
            });

            var degrees = typeof window.rotations !== 'undefined' ? window.rotations : [];
            window.rotateImage = function(evt){
                let scale = 1;
                let adId = $(evt).parent().parent().parent().find('.ad_image_id').val();
                if(adId.length == 0){
                    adId = parseInt($(evt).parent().parent().parent().find('.advertise__images_counter').text()) - 1;
                }
                let myIndex = parseInt($(evt).parent().parent().parent().find('.advertise__images_counter').text()) - 1;
                let currDegrees = null;
                if(typeof degrees[adId] !== 'undefined' && degrees[adId] <= 270){
                    currDegrees = degrees[adId] + 90;
                }else if(
                    window.uploadImages.length > 0 &&
                    window.uploadImages[myIndex].data != null &&
                    typeof window.uploadImages[myIndex].data.rotation !== 'undefined' &&
                    window.uploadImages[myIndex].data.rotation <= 270
                ){
                    currDegrees = window.uploadImages[myIndex].data.rotation + 90;
                }else{
                    currDegrees = 90;
                }


                if(currDegrees == 90 || currDegrees == 270){
                    scale = 1.4;
                }else{
                    scale = 1;
                }
                $(evt).parent().parent().find('img')[0].style.transform = "rotate("+currDegrees+"deg) scale("+scale+")";

                if($(evt).parent().parent().parent().find('.ad_image_id').val().length == 0){
                    let idx = parseInt($(evt).parent().parent().parent().find('.advertise__images_counter').text()) - 1;
                    window.uploadImages[idx].data.rotation = currDegrees;
                    // delete(degrees[adId]);
                    // degrees.splice(adId, 1);
                }else{
                    degrees[adId] = currDegrees;
                    window.rotations = degrees;
                }
            };

            window.moveUp = function(evt){
                let parent = $(evt).parent().parent().parent();
                let before = parent.prev();
                parent.insertBefore(before);

                let newIndex = parseInt(before.find('.advertise__images_counter').text()) -1;
                let oldIndex = parseInt(parent.find('.advertise__images_counter').text()) -1;

                if(newIndex > oldIndex){
                    window.uploadImages.splice(newIndex + 1, 0, window.uploadImages[oldIndex]);
                    window.uploadImages.splice(oldIndex, 1);
                }else{
                    window.uploadImages.splice(newIndex, 0, window.uploadImages[oldIndex]);
                    window.uploadImages.splice(oldIndex + 1, 1);
                }

                window.updateCounters();
            };

            window.moveDown = function(evt){
                let parent = $(evt).parent().parent().parent();
                let after = parent.next();
                if(!$(after[0]).hasClass('dz-message')){
                    parent.insertAfter(after);

                    let newIndex = parseInt(after.find('.advertise__images_counter').text()) -1;
                    let oldIndex = parseInt(parent.find('.advertise__images_counter').text()) -1;

                    if(newIndex > oldIndex){
                        window.uploadImages.splice(newIndex + 1, 0, window.uploadImages[oldIndex]);
                        window.uploadImages.splice(oldIndex, 1);
                    }else{
                        window.uploadImages.splice(newIndex, 0, window.uploadImages[oldIndex]);
                        window.uploadImages.splice(oldIndex + 1, 1);
                    }

                    window.updateCounters();
                }else{
                    return false;
                }
            };

            window.removeImage = function(evt){
                $(evt).parent().parent().parent().remove();

                if(document.querySelectorAll('.dz-image').length == 0 && document.querySelector('.main-label')){
                    document.querySelector('.main-label').style.display = 'none';
                }else if(document.querySelector('.main-label')){
                    document.querySelector('.main-label').style.display = 'block';
                }

                //Remove from the array the deleted file
                window.uploadImages = window.uploadImages.filter((val, key) => {
                    return key != parseInt($(evt).parent().parent().parent().find('.advertise__images_counter').text()) -1;
                });

                if($(evt).parent().parent().parent().find('.ad_image_id').val() > 0){
                    window.NativeEventBus.dispatchEvent("setIsLoading");

                    $.post(process.env.VUE_APP_API_URL + '/api/advertise/delete-image',
                        {
                            ad_id: sessionStorage.getItem('adId'),
                            image_id: $(evt).parent().parent().parent().find('.ad_image_id').val(),
                            edit_key: sessionStorage.getItem('key')
                        },
                        function(data) {
                            window.NativeEventBus.dispatchEvent("unsetIsLoading");
                            window.uploadCount--;

                            if(typeof window.rotations !== 'undefined' && window.rotations.length > 0){
                                //Remove the rotation information for this ad if present
                                Object.keys(window.rotations).forEach(function(val){
                                    if(val == $(evt).parent().parent().parent().find('.ad_image_id').val()){
                                        let rotations = window.rotations;
                                        delete rotations[parseInt(val)];
                                        window.rotations = rotations;
                                    }
                                });
                            }

                            //Resets the image array
                            if(window.uploadCount == 0){
                                window.uploadImages = [];
                            }
                    },
                    ).fail(function(error) {
                        console.log( "error", error );
                        window.NativeEventBus.dispatchEvent("unsetIsLoading");
                    });
                }
                window.updateCounters();
            };

            window.sortable = function(){
                let sortOptions = {
                    animation: 150,
                    disabled: isMobile,
                    filter: '.advertise__images_controls, .dz-message',
                    preventOnFilter: false,
                    delay: 0,
                    // Event when you move an item in the list or between lists
                    onMove: function (/**Event*/evt, /**Event*/originalEvent) {
                        if(evt.related.className.indexOf('dz-message') == 0){
                            evt.preventDefault();
                            return false;
                        }
                    },
                    onEnd: function (/**Event*/evt) {
                        //Avoid mess with the upload button (sometimes the image go through it)
                        if(evt.newIndex >= window.uploadImages.length){
                            evt.newIndex = window.uploadImages.length -1;
                        }

                        if(evt.newIndex > evt.oldIndex){
                            window.uploadImages.splice(evt.newIndex + 1, 0, window.uploadImages[evt.oldIndex]);
                            window.uploadImages.splice(evt.oldIndex, 1);
                        }else{
                            window.uploadImages.splice(evt.newIndex, 0, window.uploadImages[evt.oldIndex]);
                            window.uploadImages.splice(evt.oldIndex + 1, 1);
                        }

                        window.updateCounters();
                    },

                }
                //Initialize the sortable js plugin
                Sortable.create(foo, sortOptions);
            }

            window.updateCounters = function(){
                let counters = document.querySelectorAll('.advertise__images_counter');
                counters.forEach((count, idx) => {
                    count.innerHTML = idx+1;
                })

                //Make sure the add+ button keeps at the end
                $('#foo').append($('.dz-message'));
            }

            window.updateNewImageKeys = function(){
                let counters = document.querySelectorAll('.new_image_key');
                counters.forEach((count, idx) => {
                    count.value = idx;
                })
            }
        },
        computed: {
            showDropzone() {
                return typeof window !== 'undefined';
            },
            showMainImageLabel(){
                return this.instance && this.instance.files.length && document.querySelectorAll('.dz-image').length > 0;
            },
            getAdId(){
                if (typeof window !== 'undefined') {
                    return sessionStorage.getItem('adId');
                }

                return null;
            },
        },
        methods:{
            ...mapMutations([
                'setIsLoading'
            ]),
            onUpdate(){
                console.log("Events");
            },
            addFiles(){
                // this.instance.open();
            },
            loadAd(adId){
                this.setIsLoading(true);
                this.axios.get(process.env.VUE_APP_API_URL + '/api/advertise/preview/' + adId, {})
                    .then(response => {
                        this.registration = response.data.ad.reg_number;
                        this.selectedMake = response.data.ad.make_id;
                        this.models = response.data.ad.models;
                        this.selectedModel = response.data.ad.model_id;
                        this.selectedVariant = response.data.ad.variant;
                        this.selectedYear = response.data.ad.reg_year;
                        this.selectedTransmissionType = response.data.ad.transmission_type_id;
                        this.selectedColour = response.data.ad.colour_id;
                        this.selectedDoors = response.data.ad.doors;
                        this.selectedMileage = response.data.ad.mileage;
                        this.selectedAskingPrice = (parseFloat(response.data.ad.price)).toFixed(0);
                        this.selectedFuelType = response.data.ad.fuel_type_id;
                        this.selectedEngineCapacity = (response.data.ad.engine_cc / 100).toFixed(1);
                        this.selectedBodyType = response.data.ad.body_type_id;
                        this.selectedSeats = response.data.ad.seats;
                        this.selectedOwners = response.data.ad.owners;
                        this.isMileageActive = response.data.ad.odo_units === 'miles';
                        this.selectedDescription = response.data.ad.description;
                        this.selectedCarLocation = response.data.ad.region_id;
                        this.selectedYoutubeVideo = this.getYoutubeLink(response.data.ad.youtube_id);

                        if(response.data.ad.tax_due_date){
                            let taxDate = new Date(response.data.ad.tax_due_date);
                            this.selectedTaxMonth = taxDate.getMonth();
                            this.selectedTaxYear = taxDate.getFullYear();
                        }

                        if(response.data.ad.test_due_date){
                            let nctDate = new Date(response.data.ad.test_due_date);
                            this.selectedNctMonth = nctDate.getMonth();
                            this.selectedNctYear = nctDate.getFullYear();
                        }

                        let comfortFilters = response.data.ad.feature.filter( val => val.section_id == 1)
                        if(comfortFilters.length > 0){
                            this.selectedComfort = comfortFilters.map(val => val.id )
                        }

                        let stylingFilters = response.data.ad.feature.filter( val => val.section_id == 3)
                        if(stylingFilters.length > 0){
                            this.selectedStyling = stylingFilters.map(val => val.id )
                        }

                        let securityFilters = response.data.ad.feature.filter( val => val.section_id == 2)
                        if(securityFilters.length > 0){
                            this.selectedSecurity = securityFilters.map(val => val.id )
                        }

                        this.selectedContactName = response.data.ad.adv_settings.contact_name;
                        this.selectedDisplayName = response.data.ad.adv_settings.show_name;
                        this.selectedPhone = response.data.ad.adv_settings.phone;
                        this.selectedDisplayPhone = response.data.ad.adv_settings.show_phone;
                        this.selectedEmail = response.data.ad.adv_settings.email;
                        this.selectedAllowEmail = response.data.ad.adv_settings.email_optin;
                        this.selectedUserTerms = true;

                        this.setIsLoading(false);

                    })
                    .catch(error => {
                        console.error(error.response.data);
                        this.setIsLoading(false);
                    });
            },
            youtubeParser(url){
                const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
                let match = url.match(regExp);
                return (match&&match[7].length==11)? match[7] : false;
            },
            getYoutubeLink(videoId){
                if(videoId){
                    return 'https://www.youtube.com/watch?v='+videoId;
                }
            },
            getTemplate(){
                return `<div draggable="true" class="list-group-item">
                        <div class="dz-preview dz-file-preview advertise__images_photo advertise__images_photo" >
                            <div class="dz-image ">
                            <div class="advertise__images_counter">1</div>
                            <img data-dz-thumbnail class="advertise__images_image"/>
                            <input type="hidden" class="ad_image_id" name="ad_image_id" value="">
                            <input type="hidden" class="new_image_key" name="new_image_key" value="">
                        </div>
                        <div class="advertise__images_controls advertise__images_controls--desktoponly">
                            <a href="javascript:void(0)" onclick="window.rotateImage(this)" class="advertise__images_controls_rotate" title="Rotate Image"></a>
                            <a href="javascript:void(0)" onclick="window.removeImage(this)" class="advertise__images_controls_delete" data-dz-remove title="Delete image"></a>
                        </div>
                        <div class="advertise__images_controls advertise__images_controls--mobileonly">
                            <a href="javascript:void(0)" onclick="window.moveUp(this)" class="advertise__images_controls_up" title="Rotate Image"></a>
                            <a href="javascript:void(0)" onclick="window.moveDown(this)" class="advertise__images_controls_down" title="Rotate Image"></a>
                            <a href="javascript:void(0)" onclick="window.rotateImage(this)" class="advertise__images_controls_rotate" title="Rotate Image"></a>
                            <a href="javascript:void(0)" onclick="window.removeImage(this)" class="advertise__images_controls_delete"  title="Delete image"></a>
                        </div>
                        <div style="display: none">
                        <div class="dz-details">
                            <div class="dz-size">
                                <span data-dz-size></span>
                            </div>
                            <div class="dz-filename">
                                <span data-dz-name></span>
                            </div>
                        </div>
                        <div class="dz-progress">
                            <span class="dz-upload" data-dz-uploadprogress></span>
                        </div>
                        <div class="dz-error-message">
                            <span data-dz-errormessage></span>
                        </div>
                        <div class="dz-success-mark">
                            <svg width="54px" height="54px" viewBox="0 0 54 54" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                             <title>Check</title>
                             <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                              <path d="M23.5,31.8431458 L17.5852419,25.9283877 C16.0248253,24.3679711 13.4910294,24.366835 11.9289322,25.9289322 C10.3700136,27.4878508 10.3665912,30.0234455 11.9283877,31.5852419 L20.4147581,40.0716123 C20.5133999,40.1702541 20.6159315,40.2626649 20.7218615,40.3488435 C22.2835669,41.8725651 24.794234,41.8626202 26.3461564,40.3106978 L43.3106978,23.3461564 C44.8771021,21.7797521 44.8758057,19.2483887 43.3137085,17.6862915 C41.7547899,16.1273729 39.2176035,16.1255422 37.6538436,17.6893022 L23.5,31.8431458 Z M27,53 C41.3594035,53 53,41.3594035 53,27 C53,12.6405965 41.3594035,1 27,1 C12.6405965,1 1,12.6405965 1,27 C1,41.3594035 12.6405965,53 27,53 Z\" stroke-opacity=\"0.198794158\" stroke=\"#747474\" fill-opacity=\"0.816519475\" fill=\"#FFFFFF\"></path>
                              </g>
                              </svg>
                              </div>
                              <div class="dz-error-mark">
                                <svg width="54px" height="54px" viewBox="0 0 54 54" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"> <title>Error</title> <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> <g stroke="#747474" stroke-opacity="0.198794158" fill="#FFFFFF" fill-opacity="0.816519475"> <path d="M32.6568542,29 L38.3106978,23.3461564 C39.8771021,21.7797521 39.8758057,19.2483887 38.3137085,17.6862915 C36.7547899,16.1273729 34.2176035,16.1255422 32.6538436,17.6893022 L27,23.3431458 L21.3461564,17.6893022 C19.7823965,16.1255422 17.2452101,16.1273729 15.6862915,17.6862915 C14.1241943,19.2483887 14.1228979,21.7797521 15.6893022,23.3461564 L21.3431458,29 L15.6893022,34.6538436 C14.1228979,36.2202479 14.1241943,38.7516113 15.6862915,40.3137085 C17.2452101,41.8726271 19.7823965,41.8744578 21.3461564,40.3106978 L27,34.6568542 L32.6538436,40.3106978 C34.2176035,41.8744578 36.7547899,41.8726271 38.3137085,40.3137085 C39.8758057,38.7516113 39.8771021,36.2202479 38.3106978,34.6538436 L32.6568542,29 Z M27,53 C41.3594035,53 53,41.3594035 53,27 C53,12.6405965 41.3594035,1 27,1 C12.6405965,1 1,12.6405965 1,27 C1,41.3594035 12.6405965,53 27,53 Z"></path>
                              </g>
                              </g>
                            </svg>
                          </div>
                        </div>
                        </div>
                        </div>`;
            },
            openModal(){
                this.modalValidator = true;
            },
            closeModal(){
                this.modalValidator = false;
                window.myDropzone.hiddenFileInput.click();
            },
            lookup(event){
                this.showResult = true;
                this.showForm = false;
                event.preventDefault();
            },
            enableForm(){
                this.showResult = false;
                this.showForm = true;
            },
            previousStep() {
                window.NativeEventBus.dispatchEvent("previousStep");
            },
            nextStep() {
                const uploadCount = typeof window.uploadCount !== 'undefined' ? window.uploadCount : 0;
                const totalPhotos =  typeof window.uploadImages !== 'undefined' ? window.uploadImages.length + uploadCount : uploadCount;
                if( $('.dz-image-preview').length == 0 || totalPhotos == 0){
                    this.openModal();
                    return false;
                }else{
                    this.uploadPhotos();
                }
            },
            uploadPhotos(){
                this.errorResponse500 = this.errorResponse = [];
                let adId = sessionStorage.getItem('adId');


                let obj = typeof window.rotations !== 'undefined' ? Object.keys(window.rotations) : [];
                let values = typeof window.rotations !== 'undefined' ? Object.values(window.rotations) : [];

                let arrValues = [];
                for(let i=0; i < obj.length; i++){
                    arrValues.push({'id' : obj[i], 'value':values[i]});
                }
                let images = "[" + window.uploadImages.map(item => JSON.stringify(item)).toString() + "]";
                this.setIsLoading(true);

                this.axios.post(process.env.VUE_APP_API_ORIGIN_URL + '/api/advertise/upload', {
                    ad_id: adId,
                    edit_key: sessionStorage.getItem('key'),
                    youtube_id: this.selectedYoutubeVideo ? this.youtubeParser(this.selectedYoutubeVideo) : '',
                    //images: JSON.stringify(images),
                    images : images,
                    rotations: arrValues,
                    sort: window.imageSort,
                    // arrImages:
                })
                    .then(response => {
                        //Go to Next Step
                        let timeout = 2000;

                        if (response.data.total >= 10){
                            timeout = 8000;
                        }else if(response.data.total >= 5){
                            timeout = 6000;
                        }else if(response.data.total >= 1){
                            timeout = 5000;
                        }

                        if(timeout > 0){
                            setTimeout( () => {
                                window.uploadImages = [];
                                window.rotations = [];
                                this.setIsLoading(false);
                                window.NativeEventBus.dispatchEvent("goToPreview");
                            }, timeout);
                        }else{
                            window.uploadImages = [];
                            window.rotations = [];
                            this.setIsLoading(false);
                            window.NativeEventBus.dispatchEvent("goToPreview");
                        }
                    })
                    .catch(error =>
                    {
                        console.log("Error Uploading Images...");
                        if(error && error.response && error.response.status == 500){
                            this.errorResponse500 = {'error': 500} ;
                        }else if(error && error.respoonse && error.response.data){
                            this.errorResponse = error.response.data;
                        }else{
                            this.errorResponse500 = {'error': 500} ;
                        }
                        this.setIsLoading(false);
                    });
            }
        }
    }
</script>

<style>
</style>
