<template>
    <div>
        <div class="advertise__finished">
            <div class="advertise__finished_left">
                <h2>Ad Posted</h2>
                <p>Your advert will be live within 15 minutes. A password has been generated which you will need should you want to edit your advert.</p>
                <dl>
                    <dt>Your password is:</dt>
                    <dd v-if="car">Password: <span id="password">{{car.adv_settings.password}}</span></dd>
                </dl>
            </div>
            <div class="advertise__finished_right">
                <h3>Useful links</h3>
                <ul>
                    <li>To see your ad live: <router-link :to="getLiveLink" v-if="car" data-cy="link-ad">{{ getCurrentHost() }}/{{ car.id }}</router-link></li>
                    <li>To edit your ad: <router-link to="/advertise/edit" v-if="car" data-cy="link-edit">{{ getCurrentHost() }}/advertise/edit</router-link></li>
                    <li>To delete an ad: <router-link to="/advertise/delete" v-if="car" data-cy="link-delete">{{ getCurrentHost() }}/advertise/delete</router-link></li>
                    <li>Push your ad on social media:
                        <ul>
                            <li><img src="../../assets/img/facebook.svg" alt="facebook" /></li>
                            <li><img src="../../assets/img/twitter.svg" alt="twitter" /></li>
                            <li><img src="../../assets/img/instagram.svg" alt="instagram" /></li>
                        </ul>
                    </li>
                    <li>Url to forward to friends: <router-link :to="getLiveLink" v-if="car">{{ getCurrentHost() }}/{{ car.id }}</router-link></li>
                    <li>Print a brochure for your ad: <router-link :to="getPrintableLiveLink" v-if="car" data-cy="link-print">{{ getCurrentHost() }}/{{ car.id }}/print</router-link></li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import $ from "jquery";
    export default {
        name: "Finish",
        data: function () {
            return {
                car: false,
                showForm: false,
                showResult: false,
            }
        },
        mounted(){
            this.getAdData();
        },
        computed:{
            getLiveLink(){
              return '/' + (this.car.id || '')
            },
            getPrintableLiveLink(){
            return '/' + (this.car.id || '') + '/print'
            },
        },
        methods:{
            getAdData(){
                return this.axios.get(process.env.VUE_APP_API_URL + '/api/advertise/preview/' + sessionStorage.getItem('adId'), {})
                    .then(response => {
                        console.log("Success - The response isxxx:", response);
                        this.car = response.data.ad;
                        sessionStorage.setItem('key', this.car.adv_settings.password);

                        this.triggerIndexer();
                    })
                    .catch(error => console.error(error));
            },
            triggerIndexer(){
                this.axios.post(process.env.VUE_APP_API_URL + '/api/advertise/trigger-indexer', {
                    key: sessionStorage.getItem('key'),
                    ad_id: this.car.id
                })
                    .then(response => {
                        console.log("Success - The response indexer is:", response);

                        //Avoid triggering multiple times
                        sessionStorage.setItem('checkoutId', this.car.id);
                    })
                    .catch(error => console.error(error));
            },
            lookup(event){
                this.showResult = true;
                this.showForm = false;
                event.preventDefault();
            },
            enableForm(){
                this.showResult = false;
                this.showForm = true;
            },
            nextStep() {
                window.NativeEventBus.dispatchEvent("nextStep");
            },
            getCurrentHost(){
                return window.location.host;
            }
        }
    }
</script>

<style scoped>
</style>
