<template>
    <div>
        <main-header></main-header>
        <seo-meta></seo-meta>
        <div class="vld-parent">
            <loading :active.sync="isLoading"
                     :can-cancel="false"
                     :is-full-page="true">
            </loading>
        </div>
        <breadcrumbs></breadcrumbs>
        <div class="wrapper wrapper--full wrapper--grey">
            <div class="wrapper wrapper--grey">
                <div class="advertise">
                    <h1 class="adv-confirm-action-h1">Manage Your Ad</h1>
                    <div class="advertise__dealer edit-success">
                        <div class="advertise__dealer_edit-success">
                            <p>Please confirm you want to edit this ad.</p>
                            <div class="advertise__dealer_edit-success_wrapper">
                                <div class="advertise__dealer_edit-success_panel">
                                    <div class="advertise__dealer_edit-success_centered">
                                        <div class="advertise__confirm">
                                            <div class="advertise__confirm-action">
                                                <div class="image-container">
                                                    <img :src="getMainImage">
                                                </div>
                                                <div class="description">
                                                    <h2>{{formattedCarTitle}}</h2>
                                                    <p>{{formattedCarVariant}}</p>
                                                </div>
                                                <div class="separator"></div>
                                                <div class="actions">
                                                    <div class="item" @click="renewAd()">
                                                        <div class="renew"></div>
                                                        <span>RENEW for FREE</span>
                                                    </div>
                                                    <div class="item" @click="deleteAd()">
                                                        <div class="delete"></div>
                                                        <span>REMOVE</span>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
        <main-footer></main-footer>
    </div>
</template>

<script>
    import MainHeader from '@/components/MainHeader.vue'
    import MainFooter from '@/components/MainFooter'
    import Breadcrumbs from "@/components/partials/Breadcrumbs"
    import {mapMutations, mapState} from "vuex";
    import Loading from 'vue-loading-overlay';
    import SeoMeta from "@/components/partials/SeoMeta";
    export default {
        name: "AdvertiseConfirmAction",
        components: {
            SeoMeta,
            MainHeader,
            MainFooter,
            Breadcrumbs,
            Loading
        },
        data: function () {
            return {
                ad:false,
                title: "Activate Email Alert | Terrific.ie",
                description: "Activate Email Alert.",
                keywords: "Cars for Sale, Used Cars, Cars, Second Hand Cars, Electric Cars, Audi, BMW, Ford, Toyota, 7 Seaters, Land Rover",
                robots: "all",
                steps: ['reg', 'photo', 'preview', 'payment', 'finish'],
                currentStep: 0
            }
        },
        computed:{
            ...mapState([
                'isLoading'
            ]),
            getMainImage() {
                if(this.ad){
                    return this.ad.images[0].primaryURL.large;
                }
                return 'javascript:void(0)';
            },
            formattedCarTitle(){
                if(this.ad){
                    return this.ad.reg_year + ' ' + this.ad.make.make + ' ' + this.ad.model.model;
                }
                return '';
            },
            formattedCarVariant(){
                if(this.ad){
                    return this.ad.variant;
                }
                return '';
            }

        },
        serverPrefetch() {
            this.$ssrContext.title = this.title;
            this.$ssrContext.description = this.description;
            this.$ssrContext.keywords = this.keywords;
            this.$ssrContext.robots = this.robots;
            this.updateBreadcrumb();
        },
        mounted(){
            this.updateBreadcrumb();
            this.getAdData();
        },
        methods:{
            ...mapMutations([
                'setIsLoading'
            ]),
            deleteAd(){
                let adId = sessionStorage.getItem('adId');
                let key = sessionStorage.getItem('key');

                if(!adId || !key){
                    return false;
                }
                this.setIsLoading( true);
                this.axios.post(process.env.VUE_APP_API_URL + '/api/advertise/delete', {
                    edit_key: key,
                    ad_id: adId
                })
                    .then(response => {
                        this.setIsLoading(false);
                        // sessionStorage.removeItem('adId');
                        // sessionStorage.removeItem('key');
                        this.$router.push('/advertise/delete/success');
                    })
                    .catch(error => {
                        console.error(error);
                        this.setIsLoading(false);
                    });
            },
            renewAd(){
                let adId = sessionStorage.getItem('adId');
                let key = sessionStorage.getItem('key');

                if(!adId || !key){
                    return false;
                }

                this.setIsLoading( true);
                this.axios.post(process.env.VUE_APP_API_URL + '/api/advertise/renew', {
                    edit_key: key,
                    ad_id: adId
                })
                    .then(response => {
                       this.setIsLoading( false);
                        this.$router.push('/advertise/edit/success?action=renew');
                    })
                    .catch(error => {
                        console.error(error);
                        this.setIsLoading(false);
                    });
            },
            getAdData(){
                return this.axios.get(process.env.VUE_APP_API_URL + '/api/advertise/preview/' + sessionStorage.getItem('adId'), {})
                    .then(response => {
                        this.ad = response.data.ad;
                        this.ad.images = response.data.images;
                    })
                    .catch(error => console.error(error));
            },
            previousStep() {
                if(this.currentStep > 0) this.currentStep--;
            },
            nextStep() {
                this.$router.push('/private-advertiser');
            },
            updateBreadcrumb(){
                this.$store.commit('breadcrumbs/setLeafLevelName',  'Manage Your Ad');
                this.$store.commit('breadcrumbs/setLevelOne',  true);
                this.$store.commit('breadcrumbs/setFirstLevelName',  "Advertise");
                this.$store.commit('breadcrumbs/setPathLevelOne',  '/advertise/landing');

            }
        },
    }
</script>

<style scoped>

</style>
