<template>
    <div class="quicksearch first-auto" data-id="quicksearch">
        <div class="vld-parent">
            <loading :active.sync="isLoading"
                     :can-cancel="false"
                     :is-full-page="fullPage">
            </loading>
        </div>
        <form>
            <div class="make-container">
                <treeselect
                        :limit="1"
                        :searchable="false"
                        open-direction="below"
                        :multiple="true"
                        :options="makes"
                        placeholder="Select a Make"
                        :load-options="loadOptions"
                        :auto-load-root-options="false"
                        v-on:input="search($event, 'make');blockAction('make', 'open');"
                        v-model="searchMake"
                        :max-height="300"
                        :closeOnSelect="true"
                        v-on:open="blockAction('make', 'open')"
                        v-on:close="blockAction('make', 'close')"
                >
                    <div slot="value-label" slot-scope="{ node }">{{ node.raw.label }}</div>

                </treeselect>
            </div>
            <div class="treeselect-spacer" />
            <div class="model-container">
                <treeselect
                        :limit="1"
                        :searchable="false"
                        open-direction="below"
                        :multiple="true"
                        :options="makeModels"
                        :load-options="loadOptions"
                        :auto-load-root-options="false"
                        placeholder="Select a Model"
                        v-on:input="search($event, 'model');blockAction('model', 'open');"
                        v-model="searchModel"
                        v-on:open="blockAction('model', 'open')"
                        v-on:close="blockAction('model', 'close')"
                        :max-height="300"
                >
                    <div slot="value-label" slot-scope="{ node }">{{ node.raw.label }}</div>
                </treeselect>
            </div>

            <div class="treeselect-spacer" />

            <div class="form-group">
                <treeselect
                    :searchable="false"
                    :multiple="false"
                    :options="yearsFrom"
                    placeholder="Min Year"
                    :load-options="loadOptions"
                    :auto-load-root-options="false"
                    v-on:input="search($event, 'year')"
                    v-model="searchYearFrom"
                />

                <treeselect
                    :searchable="false"
                    :multiple="false"
                    :options="yearsTo"
                    placeholder="Max Year"
                    :load-options="loadOptions"
                    :auto-load-root-options="false"
                    v-on:input="search($event, 'year')"
                    v-model="searchYearTo"
                />

            </div>

            <div class="treeselect-spacer" />

            <div class="quicksearch__togglelabels">
                <label for="monthlypriceQs">Cash Price</label>
                <label for="monthlypriceQs">Monthly Price</label>
            </div>
            <label class="toggleswitch toggleswitch--light">
            <toggle-button
                id="monthlypriceQs"
                v-model="monthlyPriceSwitch"
                :margin=0
            ></toggle-button>
            </label>
            <div class="quicksearch__hidden" data-class="quicksearch__hidden">
                <select name="deposit" id="deposit" class="form-control">
                    <option>Select a Deposit / Trade-In Value</option>
                </select>
            </div>
            <div class="form-group" v-show="!monthlyPriceSwitch">
                <treeselect
                        :searchable="false"
                        :multiple="false"
                        :options="pricesFrom"
                        placeholder="Min Price"
                        :load-options="loadOptions"
                        :auto-load-root-options="false"
                        v-on:input="search($event, 'price')"
                        v-model="searchPriceFrom"
                />

                <treeselect
                        :searchable="false"
                        :multiple="false"
                        :options="pricesTo"
                        placeholder="Max Price"
                        :load-options="loadOptions"
                        :auto-load-root-options="false"
                        v-on:input="search($event, 'price')"
                        v-model="searchPriceTo"
                />

            </div>
            <div class="form-group" v-show="monthlyPriceSwitch">
                <treeselect
                        :searchable="false"
                        :multiple="false"
                        :options="financeOptions"
                        placeholder="Select a Deposit / Trade-In Value"
                        :load-options="loadOptions"
                        :auto-load-root-options="false"
                        v-on:input="search($event, 'price')"
                        v-model="searchFinanceDeposit"
                />
            </div>
            <div class="treeselect-spacer" />
            <div class="treeselect-spacer" />
            <div class="form-group" v-show="monthlyPriceSwitch">
                <treeselect
                        :searchable="false"
                        :multiple="false"
                        :options="monthlyPricesFrom"
                        placeholder="Min Per Month"
                        :load-options="loadOptions"
                        :auto-load-root-options="false"
                        v-on:input="search($event, 'price')"
                        v-model="searchMonthlyPricesFrom"
                />

                <treeselect
                        :searchable="false"
                        :multiple="false"
                        :options="monthlyPricesTo"
                        :load-options="loadOptions"
                        placeholder="Max Per Month"
                        :auto-load-root-options="false"
                        v-on:input="search($event, 'price')"
                        v-model="searchMonthlyPricesTo"
                />

            </div>
            <div class="treeselect-spacer" />
            <div class="treeselect-spacer" />

            <input type="button" id="beginSearchBtn" value="Begin Search" class="button" v-on:click="startSearch"/>
            <input  v-if="showNext" id="myBtnNext" type="button" value="Next" class="button btn-next"/>
            <!--<div class="quicksearch__advancedlink">-->
                <!--<a href="#">Advanced Search</a>-->
            <!--</div>-->
        </form>

    </div>

</template>

<script>
    import Treeselect from '@riophae/vue-treeselect'
    import Loading from 'vue-loading-overlay'
    import { ToggleButton } from 'vue-js-toggle-button/dist/ssr.index'
    import {mapMutations, mapState, mapActions} from "vuex"
    var numeral = require('numeral')
    // import $ from 'jquery'

    export default {
        name: "QuickSearchFirstAuto",
        components: {
            ToggleButton,
            Loading,
            Treeselect
        },
        props: {
            monthlyPrice: {
                type: Boolean,
                required: true
            },
            widget: {
                type: Boolean,
                required: false
            }
        },
        computed:{
            ...mapState([]),
            monthlyPriceSwitch: {
                get() {
                    return this.priceToggle;
                },
                set(value) {
                    this.priceToggle = value;
                }
            },
            searchResultData () {
                if(this.justLoaded){
                    return null;
                }
                let res = this.$store.state.searchResults[this.url];
                return res;
            }
        },
        watch: {
            searchResultData(newValue) {
                let filter = this.myFilter;
                let data = newValue;

                if(!data){
                    return false;
                }

                /**
                 *  Makes
                 **/
                this.makes = data.unfiltered.makes.buckets[0].makes.buckets.map(element => ({
                    id: this.stringToSlug(element.key),
                    label: element.key + ' (' + element.doc_count + ')',
                    docCount: element.doc_count,
                    customLabel: element.key + ' (' + element.doc_count + ')'
                }));

                /**
                 *  Models
                 **/
                if (filter != 'model' && (this.searchMake && this.searchMake.length > 0)) {
                    this.models = data.models.map(element => ({
                        id: this.stringToSlug(element.key),
                        label: element.key + ' (' + element.doc_count + ')',
                        docCount: element.doc_count,
                        customLabel: element.key + ' (' + element.doc_count + ')'
                    }));

                    this.makeModels = data.make_models.map(element => ({
                        id: this.stringToSlug(element.key),
                        label: element.key.replace('_', ' ') + ' (' + element.doc_count + ')',
                        docCount: element.doc_count,
                        customLabel: element.key + ' (' + element.doc_count + ')'
                    }));
                } else if (this.searchMake == null || (this.searchMake && this.searchMake.length == 0)) {
                    this.models = this.makeModels = [];
                    this.searchModel = null;
                }

                /**
                 *  Years
                 **/
                if (filter != 'year') {
                    this.years = data.years.map(element => ({
                        id: element.key,
                        label: element.key,
                        docCount: element.doc_count,
                        customLabel: element.key + ' (' + element.doc_count + ')'
                    }));
                }

                if (this.searchYearFrom != null) {
                    this.yearsTo = this.years.filter(element => parseInt(element.id) >= parseInt(this.searchYearFrom));
                } else {
                    this.yearsTo = this.years;
                }

                if (this.searchYearTo != null) {
                    this.yearsFrom = this.years.filter(element => parseInt(element.id) <= parseInt(this.searchYearTo));
                } else {
                    this.yearsFrom = this.years;
                }

                /**
                 *  Prices
                 **/
                if (filter != 'price') {
                    this.prices = data.prices.map(element => ({
                        id: element.key,
                        label: numeral(element.key).format("0,000"),
                        docCount: element.doc_count,
                        customLabel: numeral(element.key).format("0,000")
                    }));
                }

                if (this.searchPriceFrom != null) {
                    this.pricesTo = this.prices.filter(element => parseInt(element.id) >= parseInt(this.searchPriceFrom));
                } else {
                    this.pricesTo = this.prices;
                }

                if (this.searchPriceTo != null) {
                    this.pricesFrom = this.prices.filter(element => parseInt(element.id) <= parseInt(this.searchPriceTo));
                } else {
                    this.pricesFrom = this.prices;
                }


                /**
                 *  Deposits
                 **/
                if (filter != 'price') {
                    this.financeOptions = data.finance_options.filter(element => element.doc_count > 0).map(element => ({
                        id: element.key,
                        label: numeral(element.key).format("0,000"),
                        docCount: element.doc_count,
                        customLabel: element.key
                    }));
                }


                /**
                 *  Monthly Prices
                 **/
                if (filter != 'price') {
                    this.monthlyPrices = data.monthly_prices.map(element => ({
                        id: element.key,
                        label: numeral(element.key).format("0,000"),
                        docCount: element.doc_count,
                        customLabel: numeral(element.key).format("0,000")
                    }));
                }

                if (this.searchMonthlyPricesFrom != null) {
                    this.monthlyPricesTo = this.monthlyPrices.filter(element => parseInt(element.id) >= parseInt(this.searchMonthlyPricesFrom));
                } else {
                    this.monthlyPricesTo = this.monthlyPrices;
                }

                if (this.searchMonthlyPricesTo != null) {
                    this.monthlyPricesFrom = this.monthlyPrices.filter(element => parseInt(element.id) <= parseInt(this.searchMonthlyPricesTo));
                } else {
                    this.monthlyPricesFrom = this.monthlyPrices;
                }

                this.isLoading = false;
            }
        },
        data: function () {
            return {
                url: process.env.VUE_APP_API_URL + '/api/ad-elastic-filters?quickSearch=1&g=1',
                fullPage: true,
                isLoading: false,
                makes: [],
                models: [],
                makeModels: [],
                years: [],
                yearsFrom: [],
                yearsTo: [],
                prices: [],
                pricesFrom: [],
                pricesTo: [],
                financeOptions:[],
                monthlyPrices:[],
                monthlyPricesFrom:[],
                monthlyPricesTo:[],
                searchMake: null,
                searchModel: null,
                searchYearFrom: null,
                searchYearTo: null,
                searchPriceFrom: null,
                searchPriceTo: null,
                searchMonthlyPricesFrom: null,
                searchMonthlyPricesTo: null,
                searchFinanceDeposit: null,
                priceToggle :null,
                myFilter: null,
                justLoaded: false,
                showNext: false
            }
        },
        created(){
            if(this.priceToggle == null){
                this.priceToggle = this.monthlyPrice;
            }
        },
        mounted() {
            //Magic to trigger the watcher
            this.justLoaded = true;
            this.justLoaded = false;
            if (!this.searchResultData) {
                this.fetchSearchResults(this.url);
            }
            this.setCookieGroup();
        },
        serverPrefetch () {
            // return the Promise from the action
            // so that the component waits before rendering
            return this.fetchSearchResults(this.url);
        },

        methods: {
            ...mapMutations([]),
            ...mapActions([]),
            async fetchSearchResults (url) {
                return await this.$store.dispatch('fetchQuickSearch', url);
            },
            setCookieGroup(){
                let d = new Date();
                // expires in 24h
                d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
                let expires = "expires=" + d.toUTCString();
                document.cookie = "g=1" + ";" + expires + ";path=/";
            },
            stringToSlug (str) {
                str = str.replace(/^\s+|\s+$/g, ''); // trim
                str = str.toLowerCase();

                // remove accents, swap ñ for n, etc
                let from = "àáäâèéëêìíïîòóöôùúüûñç·/,:;";
                let to   = "aaaaeeeeiiiioooouuuunc------";
                for (let i=0, l=from.length ; i<l ; i++) {
                    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
                }

                str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
                    .replace(/\s+/g, '+') // collapse whitespace and replace by -
                    .replace(/-+/g, '+'); // collapse dashes

                return str;
            },
            resetPrice(){

                if( this.monthlyPriceSwitch ){

                    this.searchPriceFrom = null;
                    this.searchPriceTo  = null;

                }else{

                    this.searchMonthlyPricesFrom = null;
                    this.searchMonthlyPricesTo  = null;
                    this.searchFinanceDeposit = null;
                }

                this.search();
            },
            getHandler(){
                return function(e){
                    e.preventDefault();
                }
            },
            blockAction(myElement, action){

                const $body = document.querySelector('body');

                if(action == 'open'){

                    let that = this;
                    this.showNext = true;
                    this.$store.state.quickSearchOverlay = true;
                    $body.style.overflow = 'hidden';

                    setTimeout(function(){
                        if(!document.getElementsByClassName('vue-treeselect__list')[0] ){
                            that.showNext = false;
                        }

                        that.setNextButtonDimensions();

                        let makeControleHeight = 0;
                        let modelControleHeight = 0;
                        let offset = 0;
                        let difference = 0;

                        if(myElement == 'make'){
                            makeControleHeight = document.getElementsByClassName('vue-treeselect__control')[0].clientHeight;
                            offset = document.getElementsByClassName('vue-treeselect__menu')[0].offsetHeight;
                            difference = 17;
                        }else{
                            makeControleHeight = document.getElementsByClassName('vue-treeselect__control')[0].clientHeight;
                            modelControleHeight = document.getElementsByClassName('vue-treeselect__control')[1].clientHeight;
                            offset = document.getElementsByClassName('vue-treeselect__menu')[0].offsetHeight;
                            offset += modelControleHeight;
                            difference = 32;
                        }
                        document.getElementById("myBtnNext").style.top = offset + difference + makeControleHeight + "px";
                    }, 10);
                }else{
                    this.showNext = false;
                    this.$store.state.quickSearchOverlay = false;
                    $body.style.removeProperty('overflow');
                }

            },
            async loadOptions() {
            },
            async search(event, filter){

                this.myFilter = filter;

                this.url = process.env.VUE_APP_API_URL + '/api/ad-elastic-filters?quickSearch=1&g=1';

                // If the filter is make than reset the models
                if(filter == 'make'){
                    this.searchModel = [];
                }

                if(this.searchMake != null && this.searchMake.length > 0){
                    let makes = this.searchMake.join(',');
                    if(makes && makes != 'null'){
                        this.url += '&makes=' + makes;
                    }
                }

                if(this.searchModel != null && this.searchModel.length > 0){
                    let models = this.searchModel.map((myModel) => {
                        return myModel.substring(myModel.indexOf('+') + 1)
                    }).join(',');
                    if(models && models != 'null'){
                        this.url += '&models=' + models;
                    }
                }

                if(this.searchYearFrom != null){
                    this.url += '&yearFrom=' + this.searchYearFrom;
                }

                if(this.searchYearTo != null){
                    this.url += '&yearTo=' + this.searchYearTo;
                }

                if(this.searchPriceFrom != null){
                    this.url += '&priceFrom=' + this.searchPriceFrom;
                }

                if(this.searchPriceTo != null){
                    this.url += '&priceTo=' + this.searchPriceTo;
                }

                if(this.searchFinanceDeposit != null){
                    this.url += '&financeDeposit=' + this.searchFinanceDeposit;
                }

                if(this.searchMonthlyPricesFrom != null){
                    this.url += '&monthlyPriceFrom=' + this.searchMonthlyPricesFrom;
                }

                if(this.searchMonthlyPricesTo != null){
                    this.url += '&monthlyPriceTo=' + this.searchMonthlyPricesTo;
                }

                if(this.isLoading == false) {
                    this.isLoading = true;
                    this.fetchSearchResults(this.url);
                }
            },

            startSearch() {
                let query = {};
                if (this.searchMake != null) {
                    let makes = this.searchMake.join('-');
                    if(makes && makes != 'null'){
                        query.makes = makes;
                    }

                }
                if (this.searchModel != null) {
                    let models = this.searchModel.map((myModel) => {
                        return myModel.substring(myModel.indexOf('+') + 1);
                    }).join('-');
                    if(models && models != 'null') {
                        query.models = models;
                    }
                }

                if(this.searchYearFrom != null){
                    query.yearfrom = this.searchYearFrom;
                }

                if(this.searchYearTo != null){
                    query.yearto = this.searchYearTo;
                }

                if (this.searchPriceFrom != null) {
                    query.pricefrom = this.searchPriceFrom;
                }

                if (this.searchPriceTo != null) {
                    query.priceto = this.searchPriceTo;
                }

                if (this.searchFinanceDeposit != null) {
                    query.financedeposit = this.searchFinanceDeposit;
                }

                if(this.searchMonthlyPricesFrom != null){
                    query.monthlypricefrom = this.searchMonthlyPricesFrom;
                }
                if(this.searchMonthlyPricesTo != null){
                    query.monthlypriceto = this.searchMonthlyPricesTo;
                }

                this.$store.state.quickSearchOverlay = false;

                //First auto dealer group
                query.g='1';

                //Source of quick search
                query.s='1';



                let params = Object.keys(query).map(key => key + '-' + query[key]).join('/');

                if(this.widget === true){
                    let routeData = this.$router.resolve({path: '/used-cars/' + params});
                    window.open(routeData.href, '_blank');
                }else{
                    this.$router.push({path: '/used-cars/' + params});
                }

            },
            setNextButtonDimensions(){
                if(typeof document.getElementById("myBtnNext") === 'undefined'){
                    return false;
                }
                //Get the dimension of the Begin Search Button
                let buttonWidth = document.getElementById("beginSearchBtn").offsetWidth + "px";
                document.getElementById("myBtnNext").style.width = buttonWidth;
                let res = document.getElementsByClassName('quicksearch')[0];
                let paddingRight = window.getComputedStyle(res, null).getPropertyValue('padding-right');
                document.getElementById("myBtnNext").style.left = paddingRight;
            }
        }
    }
</script>

<style lang="scss">
    .vue-treeselect--open-below .vue-treeselect__menu {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    .first-auto{

        .vue-treeselect__control {
            height: 36px;
        }

        .vue-treeselect__single-value {
            padding: 0.1rem;
        }

        .vue-treeselect__placeholder {
            padding-top: 0.1rem;
        }

        .button{
            font-size: 20px !important;
        }



        .quicksearch__togglelabels{
            /*font-size: 1.2rem;*/
            /*padding: 0.5rem;*/
            padding: 0 2px 0 2px;
        }

        /**
        Css for mobile treeselect
         */
        @media only screen and (max-width: 600px) {
            .make-container .vue-treeselect__menu {
                max-height: calc(100vh - 150px) !important;
            }

            .model-container .vue-treeselect__menu {
                max-height: calc(100vh - 200px) !important;
            }
        }
    }



</style>
