<template>
    <div>
        <main-header></main-header>
        <seo-meta></seo-meta>
        <breadcrumbs></breadcrumbs>
        <div class="wrapper wrapper--full wrapper--grey">
            <div class="wrapper wrapper--grey">
                <div class="advertise">
                    <div class="advertise__dealer">
                        <img :src="require('../assets/img/car-dealership-icon.svg')">
                        <h1>Dealership Registration</h1>
                        <p>If you would like to advertise as a dealer, please fill in the form below and we will revert
                            as soon as possible.</p>
                        <div class="advertise__dealer_wrapper">
                            <div class="advertise__dealer_panel">
                                <div class="advertise__dealer_centered">
                                    <form action="javascript:void(0)">
                                        <div class="advertise__dealer_field">
                                            <label for="name" class="required">Your Name*:</label>
                                            <input type="text" name="name" id="name" v-model="name" class="form-control"  :class="errors.has('name') ? 'form-control--error' : null" placeholder="Your Name*"/>
                                            <div v-if="errors.has('name')">
                                                <div class="form-error"
                                                     v-for="(error, index) in errors.get('name')" :key="index"
                                                     v-text="error"></div>
                                            </div>
                                        </div>
                                        <div class="advertise__dealer_field">
                                            <label for="dealership" class="required">Your Dealership*:</label>
                                            <input type="text" name="dealership" id="dealership" v-model="dealership" class="form-control" :class="errors.has('dealership') ? 'form-control--error' : null" placeholder="Your Dealership*"/>
                                            <div v-if="errors.has('dealership')">
                                                <div class="form-error"
                                                     v-for="(error, index) in errors.get('dealership')" :key="index"
                                                     v-text="error"></div>
                                            </div>
                                        </div>
                                        <div class="advertise__dealer_field">
                                            <label for="phone_number" class="required">Your Phone Number*:</label>
                                            <input type="text" name="phone" id="phone" v-model="phone" class="form-control" :class="errors.has('phone') ? 'form-control--error' : null" placeholder="Your Phone Number*"/>
                                            <div v-if="errors.has('phone')">
                                                <div class="form-error"
                                                     v-for="(error, index) in errors.get('phone')" :key="index"
                                                     v-text="error"></div>
                                            </div>
                                        </div>
                                        <div class="advertise__dealer_field">
                                            <label for="phone_number" class="required">Your Email Address*:</label>
                                            <input type="text" name="email" id="email" v-model="email" class="form-control" :class="errors.has('email') ? 'form-control--error' : null" placeholder="Your Email Address*"/>
                                            <div v-if="errors.has('email')">
                                                <div class="form-error"
                                                     v-for="(error, index) in errors.get('email')" :key="index"
                                                     v-text="error"></div>
                                            </div>
                                        </div>
                                        <div class="advertise__dealer_field">
                                            <label for="message" class="required">Comments:</label>
                                            <textarea rows="5" name="message" id="message" v-model="message" class="form-control" :class="errors.has('message') ? 'form-control--error' : null" placeholder="Comments"/>
                                            <div v-if="errors.has('message')">
                                                <div class="form-error"
                                                     v-for="(error, index) in errors.get('message')" :key="index"
                                                     v-text="error"></div>
                                            </div>
                                        </div>

                                        <div class="advertise__buttons advertise__buttons advertise__dealer_buttons">
                                            <button class="advertise__button advertise__dealer_buttons_button" v-on:click="submitForm()">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
        <main-footer></main-footer>
    </div>
</template>

<script>
    import MainHeader from '@/components/MainHeader.vue'
    import MainFooter from '@/components/MainFooter'
    import Breadcrumbs from "@/components/partials/Breadcrumbs"
    import {mapMutations, mapState} from "vuex";
    import SeoMeta from "@/components/partials/SeoMeta";

    class Errors {
        constructor() {
            this.errors = {};
        }

        any() {
            return Object.keys(this.errors).length > 0;
        }

        has(field) {
            //return this.errors.hasOwnProperty(field);
            return Object.prototype.hasOwnProperty.call(this.errors, field);
        }

        get(field) {
            if (this.errors[field]) {
                return this.errors[field];
            }
        }

        record(errors) {
            this.errors = errors;
        }

        clear(field) {
            delete this.errors[field];
        }
    }

    export default {
        name: "DealerRegistration",
        components: {
            SeoMeta,
            MainHeader,
            MainFooter,
            Breadcrumbs
        },
        data: function () {
            return {
                name: '',
                dealership: '',
                phone: '',
                email: '',
                message: '',
                sent:false,
                title: "Activate Email Alert | Terrific.ie",
                description: "Activate Email Alert.",
                keywords: "Cars for Sale, Used Cars, Cars, Second Hand Cars, Electric Cars, Audi, BMW, Ford, Toyota, 7 Seaters, Land Rover",
                robots: "all",
                errors: new Errors(),
            }
        },
        serverPrefetch() {
            this.$ssrContext.title = this.title;
            this.$ssrContext.description = this.description;
            this.$ssrContext.keywords = this.keywords;
            this.$ssrContext.robots = this.robots;
            this.updateBreadcrumb();
        },
        mounted(){
            this.updateBreadcrumb();
        },
        computed:{
            ...mapState([
                'isLoading'
            ]),
        },
        methods:{
            ...mapMutations([
                'setIsLoading'
            ]),
            previousStep() {
                if(this.currentStep > 0) this.currentStep--;
            },
            validate(){

            },
            submitForm(){
                return this.axios.post(process.env.VUE_APP_API_URL + '/api/advertise/dealer', {
                    name: this.name,
                    dealership: this.dealership,
                    phone: this.phone,
                    email: this.email,
                    message: this.message
                })
                    .then(response => {
                        this.sent = true;
                        this.setIsLoading(false);
                        this.nextStep();
                    })
                    .catch(error => {
                        console.error(error.response.data);
                        this.setIsLoading(false);
                        this.errors.record(error.response.data);
                    });
            },
            nextStep() {
                this.$router.push('/dealer-registration/success');
            },
            updateBreadcrumb(){
                this.$store.commit('breadcrumbs/setLeafLevelName',  'Dealer Registration');
                this.$store.commit('breadcrumbs/setLevelOne',  true);
                this.$store.commit('breadcrumbs/setFirstLevelName',  "Advertise");
                this.$store.commit('breadcrumbs/setPathLevelOne',  '/advertise/landing');

            }
        },
    }
</script>

<style scoped>

</style>
