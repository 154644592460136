<template>
    <div>
        <main-header></main-header>
        <seo-meta></seo-meta>
        <breadcrumbs></breadcrumbs>
        <div class="wrapper wrapper--full wrapper--grey">
            <div class="wrapper wrapper--grey">

                <div class="advertise">
                    <h1>Manage Your Ad</h1>
                    <div class="advertise__manage">
                        <div class="advertise__manage_wrapper">
                            <p>This section allows private advertisers to manage their ad.</p>
                            <div class="advertise__manage_panel">
                                <div class='advertise__manage_panel--items'>
                                    <div class="icon edit">
                                    </div>
                                    <router-link :to="'/advertise/auth/login?action=edit'" data-cy="edit" class="advertise__link">Edit an Ad</router-link>
                                </div>
                                <div class='advertise__manage_panel--items'>
                                    <div class="icon delete">
                                    </div>
                                    <router-link :to="'/advertise/auth/login?action=delete'" class="advertise__link">Remove an Ad</router-link>
                                </div>
                                <div class='advertise__manage_panel--items'>
                                    <div class="icon renew">
                                    </div>
                                    <router-link :to="'/advertise/auth/login?action=renew'" class="advertise__link">Renew an Ad</router-link>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

            </div>
        </div>
        <main-footer></main-footer>
    </div>
</template>

<script>
    import MainHeader from '@/components/MainHeader.vue'
    import MainFooter from '@/components/MainFooter'
    import Breadcrumbs from "@/components/partials/Breadcrumbs"
    import SeoMeta from "@/components/partials/SeoMeta";
    export default {
        name: "ManageAdvert",
        components: {
            SeoMeta,
            MainHeader,
            MainFooter,
            Breadcrumbs
        },
        data: function () {
            return {
                title: "Used Cars, Cars in Ireland, Electric Cars, 7 Seaters, SUV’s | Terrific.ie",
                description: "Terrific.ie details used cars for sale in Ireland including electric cars, 7 seaters and SUV’s.",
                keywords: "Used Cars, Cars for Sale, Second Hand Cars, Electric Cars, Audi, BMW, Ford, Toyota, 7 Seaters,  Land Rover, People Carriers",
                robots: "all"
            }
        },
        serverPrefetch() {
            this.$ssrContext.title = this.title;
            this.$ssrContext.description = this.description;
            this.$ssrContext.keywords = this.keywords;
            this.$ssrContext.robots = this.robots;
            this.updateBreadcrumb();
            // this.getAd();
        },
        mounted(){
          this.updateBreadcrumb();
        },
        methods: {
            updateBreadcrumb(){
                this.$store.commit('breadcrumbs/setLeafLevelName',  'Manage Your Ad');
                this.$store.commit('breadcrumbs/setFirstLevelName',  "Advertise");
                this.$store.commit('breadcrumbs/setPathLevelOne',  '/advertise/landing');
                this.$store.commit('breadcrumbs/setLevelOne',  true);

                this.$store.commit('breadcrumbs/setLevelTwo',  false);
                this.$store.commit('breadcrumbs/setLevelThree',  false);
                this.$store.commit('breadcrumbs/setLevelFour',  false);
            }
        }
    }
</script>

<style scoped>

</style>
