<template>
    <div>
        <main-header></main-header>
        <breadcrumbs></breadcrumbs>


        <div class="wrapper wrapper--grey wrapper--full industry-news">

            <div class="news">
                <h1>Industry News</h1>

                <div class="grid grid--articles">
                    <div class="articles_flex_item">
                        <div class="articles__articlecolumns" v-if="this.industryNews">
                            <div v-for="news in this.industryNews.data" :key="news.id" class="articles__article">
                                <div class="articles__article_image">
                                    <a :href="getLink(news)">
                                        <img :src="getArticleImage(news)" width="435px" height="290px">
                                    </a>
                                </div>
                                <div class="news__article_content">
                                    <a :href="getLink(news)">
                                        <div v-html="getHeadingTag(news)"></div>
                                    </a>
                                    <small>{{formattedDate(news.created_at)}}</small>
                                    <div class="main-paragraph"><p>{{getArticleMainParagraph(news)}}</p></div>
                                    <a :href="getLink(news)" class="green">Read the full article</a>
                                </div>
                            </div>
                        </div>
                        <div class="pagination">
                            <pagination
                                v-model="page"
                                :records="this.$store.state.industrynews.total"
                                :options="paginationOptions"
                                :per-page="parseInt(this.$store.state.industrynews.perPage)"
                                :vuex=false
                                @paginate="pageChanged">
                            </pagination>
                        </div>
                    </div>

                    <div class="articles_flex_item articles_flex_item--right">
                        <search-by-make modifier="searchbymake--all"></search-by-make>
                        <!--                        <news-ctas></news-ctas>-->
                    </div>

                </div>
            </div>
        </div>
        <main-footer></main-footer>
    </div>
</template>

<script>
import SearchByMake from '@/components/SearchByMake'
import MainHeader from '@/components/MainHeader.vue'
import MainFooter from '@/components/MainFooter'
import Breadcrumbs from '@/components/partials/Breadcrumbs'
import {mapActions, mapMutations, mapState} from "vuex";
import Pagination from "vue-pagination-2";
import moment from 'moment';
import ReviewsPagination from "@/components/paginator/ReviewsPagination";


export default {
    name: "News",
    props:{
        paramPage: {
            type: String,
            required: false
        }
    },
    components: {
        MainHeader,
        MainFooter,
        Breadcrumbs,
        SearchByMake,
        Pagination
    },
    data: function () {
        return {
            title: "Industry News | Terrific.ie",
            description: "Industry News Terrific.ie",
            keywords: "Car Reviews, Reviews of Cars, Used Car Reviews, Electric Cars Reviewed, Audi Reviews, BMW Reviews, Ford Reviews, Toyota Reviews, 7 Seaters Reviewed, Land Rover Reviewed, People Carriers Reviewed",
            paragraphPlaceholder : 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
            robots: "all",
            routeName: this.$router.currentRoute.name,
            edjsHTML: require("editorjs-html"),
            paginationOptions:{
                template: ReviewsPagination,
                edgeNavigation:true,
                chunk:6,
                chunksNavigation:'scroll',
                texts:{
                    count:"Showing {from} to {to} of {count} articles|{count} articles|",
                    first:"First",
                    last:"Last"
                }
            },
        }
    },
    watch: {
        $route() {
            this.getIndustryNews();
        }
    },
    serverPrefetch() {
        if(this.currPage < 1){
            this.$ssrContext.error404 = true;
            this.$ssrContext.redirect = '/404';
            return false;
        }
        this.$store.commit('industrynews/setCurrentPage', this.currPage);
        return this.getIndustryNews();
    },
    mounted() {
        if(this.currPage < 1){
            this.$store.commit('industrynews/setCurrentPage', 1);
        }
        this.$store.commit('industrynews/setCurrentPage', this.currPage);
        if (this.industryNews == null) {
            this.getIndustryNews();
        }else{
            this.$store.commit('industrynews/setTotal', this.industryNews.meta.total);
            this.$store.commit('industrynews/setPerPage', this.industryNews.meta.per_page);
            this.$store.commit('industrynews/setCurrentPage', this.industryNews.meta.current_page);
            this.$store.commit('industrynews/setFrom', this.industryNews.meta.from);
            this.$store.commit('industrynews/setTo', this.industryNews.meta.to);
        }
    },

    computed:{
        ...mapState([
            'url',
            'searchResults',
            'searchOrderBy',
            'pagination',
            'currentPageName',
        ]),
        currPage() {
            return typeof this.paramPage !== 'undefined' ? parseInt(this.paramPage) : 1;
        },
        industryNews() {
            return this.$store.state.industrynews.industryNews[this.page];
        },
        page: {
            get() {
                return this.$store.state.industrynews.currentPage;
            },
            set(value) {
                this.$store.commit('industrynews/setCurrentPage', value);
            }
        },
    },
    methods: {
        ...mapMutations([
            'setPagination',
            'setCurrentPageName',
        ]),
        ...mapActions([
            'orderByChangeAction',
            'performSearchAction',
            'updateUrlAction',
            'performSearchAction'
        ]),
        pageChanged(){
            // this.setRequestedPage(this.page);
            if(this.$router.currentRoute.path.match(/page\/(\d+)/)){
                return this.$router.push({path: this.$router.currentRoute.path.replace(/page\/(\d+)/,'page/'+ this.page)});
            }else{
                return this.$router.push({path: this.$router.currentRoute.path + '/page/' + this.page});
            }
            window.scrollTo(0, 0);
        },
        getLinkNews(item){
            return '/news-article/' + item.slug;
        },
        updateBreadCrumb() {

            this.$store.commit('setInternalSource', false);

            this.$store.commit('breadcrumbs/setFirstLevelName', "Industry News");
            this.$store.commit('breadcrumbs/setPathLevelOne', '/news');

            this.$store.commit('breadcrumbs/setLevelTwo', false);
            this.$store.commit('breadcrumbs/setSecondLevelName', false);
            this.$store.commit('breadcrumbs/setPathLevelTwo', "");

            this.$store.commit('breadcrumbs/setLevelThree', false);
            this.$store.commit('breadcrumbs/setThirdLevelName', '');

            this.$store.commit('breadcrumbs/setLevelFour', false);
            this.$store.commit('breadcrumbs/setFourthLevelName', '');
        },
         async getIndustryNews() {

            if(this.currPage < 1) {
                this.$router.push({path:'/not-found/'});
                return;
            }

            this.$store.commit('industrynews/setCurrentPage', this.currPage);
            await this.$store.dispatch('industrynews/fetchIndustryNews', this.page);

            this.$store.commit('industrynews/setTotal', this.industryNews.meta.total);
            this.$store.commit('industrynews/setPerPage', this.industryNews.meta.per_page);
            this.$store.commit('industrynews/setCurrentPage', this.industryNews.meta.current_page);
            this.$store.commit('industrynews/setFrom', this.industryNews.meta.from);
            this.$store.commit('industrynews/setTo', this.industryNews.meta.to);

        },
        checkImgTagExist(node){
            return /<img[^>]+src="https:\/\/([^">]+)/g.test(node);
        },
        checkHeadingExist(node){
            return /<h1/g.test(node);
        },
        checkParagraphExist(node){
            return /<p/g.test(node);
        },
        getHeadingTag(node){
            let res = '<h3>' + node.title + '</h3>';
            let val = '';
            if(!this.parsedIndustryNews(node)){
                return res;
            }
            for(let i=0; i < this.parsedIndustryNews(node).length; i++){
                val = this.parsedIndustryNews(node)[i];
                if( this.checkHeadingExist(val)) {
                    res = val.replace(/(<([^>]+)>)/ig, "");
                    res = '<h3>' + res + '</h3>';
                }
                break;
            }
            return res;
        },
        handleReturn(res){
            let arrRet = res.split(' ').slice(0, 44);
            res = arrRet.join(' ');
            if(arrRet.length >= 44){
                res+='...';
            }
            return res;
        },
        getArticleMainParagraph(node){
            let res = this.paragraphPlaceholder;
            if(!this.parsedIndustryNews(node)){
                return this.handleReturn(res);
            }
            let val = '';
            for(let i=0; i < this.parsedIndustryNews(node).length; i++){
                val = this.parsedIndustryNews(node)[i];
                if (this.checkParagraphExist(val)) {
                    var match = val.match(/<\s*p[^>]*>([^<]*)<\s*\/\s*p\s*>/);
                    if (match && match.length > 1) {
                        res = match[1];
                    } else {
                        res = '';
                    }
                    break;
                }

            }
            return this.handleReturn(res);
        },
        getArticleImage(node){
            let res = require('../assets/img/photos/missing/large.jpg');
            if(!this.parsedIndustryNews(node)){
                return res;
            }

            let val = '';
            for(let i=0; i < this.parsedIndustryNews(node).length; i++){
                val = this.parsedIndustryNews(node)[i];
                if( this.checkImgTagExist(val)){
                    var match = val.match(/\<img.+src\=(?:\"|\')(.+?)(?:\"|\')(?:.+?)\>/);
                    res = match[1];
                    break;
                }
            }

            return res;
        },
        parsedIndustryNews(node){
            let parsedData = '';
            if(typeof  node.data_json !== 'undefined'){
                try {
                    parsedData = JSON.parse(node.data_json);
                    parsedData = this.edjsHTML().parse(JSON.parse(node.data_json));
                } catch (e) {
                    parsedData = ['<div></div>'];
                }
            }
            return parsedData;
        },
        getLink(node){
            return '/news-article/' + node.slug;
        },
        formattedDate(date) {
            return moment(date).format('MMMM DD, YYYY');
        },
    }
}
</script>

<style scoped>

</style>
