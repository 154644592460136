<template>
    <div>
        <main-header></main-header>
        <breadcrumbs></breadcrumbs>
        <div class="wrapper wrapper--full wrapper--grey">
            <div class="wrapper wrapper--grey">
                <div class="advertise">
                    <div class="advertise__dealer success">
                        <div class="advertise__dealer_success">
                            <p>Thanks for your interest, we will get in touch soon!</p>
                            <div class="advertise__dealer_success_wrapper">
                                <div class="advertise__dealer_success_panel">
                                    <div class="advertise__dealer_success_centered">
                                        <div class="bg"></div>
                                        <h1>Thank You!</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
        <main-footer></main-footer>
    </div>
</template>

<script>
    import MainHeader from '@/components/MainHeader.vue'
    import MainFooter from '@/components/MainFooter'
    import Breadcrumbs from "@/components/partials/Breadcrumbs"
    export default {
        name: "DealerRegistrationSuccess",
        components: {
            MainHeader,
            MainFooter,
            Breadcrumbs
        },
        data: function () {
            return {
                title: "Activate Email Alert | Terrific.ie",
                description: "Activate Email Alert.",
                keywords: "Cars for Sale, Used Cars, Cars, Second Hand Cars, Electric Cars, Audi, BMW, Ford, Toyota, 7 Seaters, Land Rover",
                robots: "all",
                steps: ['reg', 'photo', 'preview', 'payment', 'finish'],
                currentStep: 0
            }
        },
        serverPrefetch() {
            this.$ssrContext.title = this.title;
            this.$ssrContext.description = this.description;
            this.$ssrContext.keywords = this.keywords;
            this.$ssrContext.robots = this.robots;
            this.updateBreadcrumb();
        },
        mounted(){
            this.updateBreadcrumb();
        },
        methods:{
            previousStep() {
                if(this.currentStep > 0) this.currentStep--;
            },
            nextStep() {
                this.$router.push('/dealer-registration/success');
            },
            updateBreadcrumb(){
                this.$store.commit('breadcrumbs/setLeafLevelName',  'Dealer Registration');
                this.$store.commit('breadcrumbs/setLevelOne',  true);
                this.$store.commit('breadcrumbs/setFirstLevelName',  "Advertise");
                this.$store.commit('breadcrumbs/setPathLevelOne',  '/advertise/landing');

            }
        },
    }
</script>

<style scoped>

</style>
