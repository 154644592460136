<template>
    <div>
        <main-header></main-header>
        <SeoMeta></SeoMeta>
        <div class="vld-parent">
            <loading :active.sync="isLoading"
                     :can-cancel="false"
                     :is-full-page="true">
            </loading>
        </div>
        <breadcrumbs></breadcrumbs>
        <div class="wrapper wrapper--full wrapper--grey">
            <div class="wrapper wrapper--grey">

                <div class="advertise">
                    <div class="advertise__back" v-if="showSteps"><a href="javascript:void(0)" v-on:click="previousStep()">Back</a></div>
                    <div class="advertise__step" v-if="showSteps">Step {{currentStep+1}} / 5</div>

                    <h1 v-if="showSteps">
                        <span>Advertise Your Car for €5</span>
                    </h1>

                    <div class="advertise__stages" v-if="showSteps">
                        <ul>
                            <li v-for="(fieldKeys, index) in steps" :key="index" :class="{'no-pointer':checkDisableSteps(currentStep, index), 'hide':skipPayment(fieldKeys), current: index==currentStep, complete: ((currentStep > index)) ? true : false}" v-on:click="checkGoToStep(fieldKeys, index)">
                                <a v-if="currentStep > index" href="javascript:void(0)" :class="{'no-pointer':checkDisableSteps(currentStep, index)}">{{fieldKeys}}</a>
                                <span v-else>{{fieldKeys}}</span>
                            </li>
                        </ul>
                    </div>

                    <div v-if="steps[currentStep] == 'Details'">
                        <reg-details></reg-details>
                    </div>

                    <div v-if="steps[currentStep] == 'Add Photo'">
                        <photos></photos>
                    </div>

                    <div v-if="steps[currentStep] == 'Preview'">
                        <preview></preview>
                    </div>

                    <div v-if="steps[currentStep] == 'Payment'">
                        <payment></payment>
                    </div>

                    <div v-if="steps[currentStep] == 'Finish'">
                        <finish></finish>
                    </div>

                </div>

            </div>
        </div>
        <main-footer></main-footer>
    </div>
</template>

<script>
    import MainHeader from '@/components/MainHeader.vue'
    import MainFooter from '@/components/MainFooter'
    import Breadcrumbs from "@/components/partials/Breadcrumbs"
    import RegDetails from "@/components/advertise/RegDetails";
    import NativeEventBus from "@/native-event-bus";
    import Loading from 'vue-loading-overlay';
    import Photos from "@/components/advertise/Photos";
    import Preview from "@/components/advertise/Preview";
    import Finish from "@/components/advertise/Finish";
    import Payment from "@/components/advertise/Payment";
    import {mapState} from "vuex";
    import SeoMeta from "@/components/partials/SeoMeta";

    export default {
        name: "PrivateAdvertiser",
        components: {
            SeoMeta,
            Loading,
            Payment,
            Finish,
            Preview,
            Photos,
            RegDetails,
            MainHeader,
            MainFooter,
            Breadcrumbs
        },
        data: function () {
            return {
                // title: "Activate Email Alert | Terrific.ie",
                // description: "Activate Email Alert.",
                // keywords: "Cars for Sale, Used Cars, Cars, Second Hand Cars, Electric Cars, Audi, BMW, Ford, Toyota, 7 Seaters, Land Rover",
                // robots: "all",
                steps: ['Details', 'Add Photo', 'Preview', 'Payment', 'Finish'],
                currentStep: this.getStep(),
            }
        },
        serverPrefetch() {
            this.$ssrContext.title = this.title;
            this.$ssrContext.description = this.description;
            this.$ssrContext.keywords = this.keywords;
            this.$ssrContext.robots = this.robots;
            this.updateBreadcrumb();
        },
        mounted(){

            if(this.$route.query.start == 1){
                sessionStorage.removeItem('adId');
                sessionStorage.removeItem('checkoutId');
                sessionStorage.removeItem('key');
                this.$router.push(this.$router.currentRoute.path);
            }
            this.updateBreadcrumb();

            if(this.$router.currentRoute.name == 'advertise-checkout-success'){
                this.currentStep = 4;
            }

            window.NativeEventBus = new NativeEventBus;

            window.NativeEventBus.addEventListener('goToPreview', () => {
                this.goToPreview();
            });

            window.NativeEventBus.addEventListener('nextStep',  () => {
                this.nextStep();
            });

            window.NativeEventBus.addEventListener('goToEditSuccess',  () => {
                this.goToEditSuccess();
            });

            window.NativeEventBus.addEventListener('previousStep',  () => {
                this.previousStep();
            });

            if(this.$router.currentRoute.name == 'advertise-checkout-success'){
                this.$router.push('/advertise/checkout/success');
            }

            if(this.$route.query.step){
                this.currentStep = this.$route.query.step -1;
            }

            // this.currentStep = 3;
        },
        computed: {
            ...mapState([
                'isLoading'
            ]),
            showSteps(){
                    return this.$route.query.action !== 'renew';
            }
        },
        methods:{
            getStep(){
                let step = 0;
                switch (this.$router.currentRoute.name ){
                    case  'advertise-checkout-success':
                        step = 4;
                        break;
                    case 'advertise-edit-success':
                        step = 4;
                        break;
                }
                return step;
            },
            skipPayment(step){
                if(typeof window === 'undefined') return false;

                return typeof sessionStorage.getItem('editMode') != 'undefined' &&
                    sessionStorage.getItem('editMode') == 'true' &&
                    step == 'Payment';
            },
            checkDisableSteps(step, index){
                return this.currentStep < index || ['advertise-checkout-success', 'advertise-edit-success'].indexOf(this.$router.currentRoute.name) != -1;
            },
            checkGoToStep(step, index){

                if(this.checkDisableSteps(step, index)){
                    return false;
                }

                if(this.currentStep < index){
                    return false;
                }

                return this.goToStep(step);
            },
            goToStep(step) {
                if(this.currentStep > step){
                    return false;
                }

                switch (step){
                    case 'Details':
                        // if(sessionStorage.getItem('adId') && sessionStorage.getItem('adId') > 0){
                            this.currentStep = 0;
                            if( typeof window.NativeEventBus1 !== 'undefined') window.NativeEventBus1.dispatchEvent("enableRegForm");
                        // }
                        break;
                    case 'Add Photo':
                        this.currentStep = 1;
                        break;
                    case 'Preview':
                        this.currentStep = 2;
                        break;
                    case 'Payment':
                        this.currentStep = 3;
                        break;
                    case 'Finish':
                        this.currentStep = 4;
                        break;

                }
                return false;
            },
            previousStep() {
                window.scrollTo(0, 0);
                if(this.currentStep > 0){
                    this.currentStep--;
                }else{
                    window.NativeEventBus.dispatchEvent("disableResult");
                }
            },
            setStep(step){
                window.scrollTo(0, 0);
                this.currentStep = step;
            },
            nextStep() {
                window.scrollTo(0, 0);
                if( this.currentStep < this.steps.length -1) this.currentStep++;
            },
            goToEditSuccess(){
                window.location.href = '/advertise/edit/success';
            },
            goToPreview(){
                this.currentStep = 2;
            },
            updateCurrentStep(){
                this.currentStep = window.currentStep;
            },
            updateBreadcrumb(){
                this.$store.commit('breadcrumbs/setLeafLevelName',  'Private Seller');
                this.$store.commit('breadcrumbs/setLevelOne',  true);
                this.$store.commit('breadcrumbs/setFirstLevelName',  "Advertise");
                this.$store.commit('breadcrumbs/setPathLevelOne',  '/advertise/landing');

                this.$store.commit('breadcrumbs/setLevelTwo',  false);
                this.$store.commit('breadcrumbs/setLevelThree',  false);
                this.$store.commit('breadcrumbs/setLevelFour',  false);

            }
        },
    }
</script>

<style scoped>

</style>
