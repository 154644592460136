<template>

    <div class="advertise__payment">
        <div class="advertise__payment__panel advertise__payment__panel--mobile-no-bg payment">
            <div class="advertise__payment__summary payment">
                <h1>Payment</h1>
                <p>You are listing your vehicle on Terrific.ie until sold.</p>
                <input class="form-control car-price"  type="text" readonly="readonly" value="Price: €5.00">
                <p>Use this secure form to complete your order.</p>
            </div>
        </div>

        <div class="advertise__payment__panel advertise__payment__panel--mobile-no-bg">
            <div class="advertise__payment__summary stripe">
                <!--                <form action="javascript:void(0)">-->
                <!--                    <label for="card-number">Card Information</label>-->
                <!--                    <div id="payment-element">-->
                <!--                        <div class="loader">Loading Stripe ...</div>-->
                <!--                    </div>-->
                <!--                    <p v-if="errorMessage.length > 0" class="form-error">{{errorMessage}}</p>-->
                <!--                    <button type="button" class="btn button" data-cy="pay" @click="pay()">Pay €5.00</button>-->
                <!--                    <div class="icon-stripe"><img src="../../assets/img/powered-by-stripe.svg" ></div>-->
                <!--                </form>-->

                <form action="javascript:void(0)"  id="payment-form">
                    <label class="card-info" for="card-number">Card Information</label>
                    <div id="card-element"><!--Stripe.js injects the Card Element--></div>
                    <p v-if="errorMessage.length > 0" class="form-error">{{errorMessage}}</p>
                    <button id="submit" type="button" class="btn button" data-cy="pay" @click="payWithCard()" >
                        <div class="spinner hidden" id="spinner"></div>
                        <span id="button-text">Pay €5.00</span>
                    </button>
                    <p id="card-error" role="alert"></p>
                    <p class="result-message hidden">
                        Payment succeeded, see the result in your
                        <a href="" target="_blank">Stripe dashboard.</a> Refresh the page to pay again.
                    </p>
                    <div class="icon-stripe"><img src="../../assets/img/powered-by-stripe.svg" ></div>
                </form>
<!--                <button id="submit" @click="pay()"></button>-->
            </div>
        </div>
    </div>




</template>

<script>
    import {mapMutations} from "vuex";
    export default {
        name: "Payment",
        components:{
        },
        data: function () {
            return {
                ad: false,
                elements: false,
                stripe:false,
                errorMessage:'',
                name:'',
                billing_email: '',
                card:false,
                cardElement:false,
                client_secret: '',
            }
        },
        mounted(){
            this.addStripeScriptHead();
            this.getAdData();
            setTimeout(() => this.getPaymentIntent(), 500 );
        },
        methods:{
            ...mapMutations([
                'setIsLoading'
            ]),
            getAdData(){
                return this.axios.get(process.env.VUE_APP_API_URL + '/api/advertise/preview/' + sessionStorage.getItem('adId'), {})
                    .then(response => {
                        this.ad = response.data.ad;
                    })
                    .catch(error => console.error(error));
            },
            pay(){
                this.stripePayment();
            },
            startStripe(publishableKey) {
                this.stripe = Stripe(publishableKey);
                const options = {
                    clientSecret: this.client_secret,
                };
                this.elements = this.stripe.elements(options);
                let style = {
                    base: {
                        color: "#32325d",
                        fontFamily: '"Rubik", sans-serif',
                        fontSmoothing: "antialiased",
                        fontSize: "16px",
                        "::placeholder": {
                            color: "#32325d"
                        }
                    },
                    invalid: {
                        fontFamily: '"Rubik", sans-serif',
                        color: "#fa755a",
                        iconColor: "#fa755a"
                    }
                };
                this.cardElement = this.elements.create("card", {style : style});
                this.cardElement.mount("#card-element");

                this.cardElement.on("change", function (event) {
                    // Disable the Pay button if there are no card details in the Element
                    document.querySelector("button").disabled = event.empty;
                    document.querySelector("#card-error").textContent = event.error ? event.error.message : "";
                });

                var form = document.getElementById("payment-form");
                let that = this;
                form.addEventListener("submit", function(event) {
                    event.preventDefault();
                    // Complete payment when the submit button is clicked
                    that.payWithCard();
                });
            },
            payWithCard(){
                // Calls stripe.confirmCardPayment
                // If the card requires authentication Stripe shows a pop-up modal to
                // prompt the user to enter authentication details without leaving your page.
                this.setIsLoading(true);
                let that = this;
                this.stripe
                    .confirmCardPayment(this.client_secret, {
                        payment_method: {
                            card: this.cardElement
                        }
                    })
                    .then(function(result) {
                        if (result.error) {
                            // Show error to your customer
                            //showError(result.error.message);
                            console.error(result.error.message);
                            that.setIsLoading(false);
                        } else {
                            // The payment succeeded!
                            console.log(result.paymentIntent.id);
                            that.setIsLoading(false);
                            window.location.href = window.location.protocol + '//' + window.location.host + '/advertise/checkout/success';
                        }
                    });
            },
            stripePayment() {
                this.setIsLoading(false);
                let objParams = {
                    elements: this.elements,
                    confirmParams: {
                        return_url: window.location.protocol + '//' + window.location.host + '/advertise/checkout/success'
                    }
                };
                this.stripe.confirmPayment(objParams).then((result) => {
                    // Handle result.error or result.paymentIntent
                    if(typeof result.error !== 'undefined' && result.error.message.length > 0){
                        this.errorMessage = result.error.message;
                    }
                });
            },
            getPaymentIntent(){
                this.setIsLoading(true);

                this.axios.post(process.env.VUE_APP_API_URL + '/api/advertise/payment/intent', {
                    vehicle_id: sessionStorage.getItem('adId'),
                })
                .then(response => {
                    //Go to Next Step
                    this.client_secret = response.data.intent.client_secret;
                    this.startStripe(response.data.keys.publishable);
                    this.setIsLoading(false);
                })
                .catch(error =>
                {
                    console.error(error);
                    this.setIsLoading(false);
                });
            },
            addStripeScriptHead(){
                const script = document.createElement('script');
                script.src = 'https://js.stripe.com/v3/';
                document.getElementsByTagName('head')[0].appendChild(script);
            },
            nextStep() {
                window.NativeEventBus.dispatchEvent("nextStep");
            }
        }
    }
</script>

<style>

</style>
